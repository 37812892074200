import * as classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import { LinkProps, Link as RouterLink } from "react-router-dom";
import ExternalLinkIcon from "./icon-old/external-link.svg";
import EyeIcon from "./icon-old/eye.svg";
import * as linkCss from "./link.module.css";
import * as wrapCss from "./wrap.module.css";

export function Link(props: LinkProps) {
  return <RouterLink {...props} className={linkCss.link} />;
}

export function ExternalLink({
  url,
  children,
  className,
  iconClassName,
  onClick,
  showIcon = true,
  newTab = true,
  eyeIcon = false,
}: {
  children: ReactNode;
  className?: string;
  iconClassName?: string;
  url?: string | undefined;
  onClick?: MouseEventHandler;
  showIcon?: boolean;
  newTab?: boolean;
  eyeIcon?: boolean;
}) {
  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation();
  };
  return (
    <a
      className={classNames(linkCss.externalLink, className)}
      href={url}
      onClick={onClick ? onClick : handleClick}
      rel="noreferrer"
      target={newTab ? "_blank" : undefined}
    >
      {eyeIcon && (
        <EyeIcon
          className={classNames(linkCss.externalLinkIcon, iconClassName)}
        />
      )}
      <span className={classNames(linkCss.linkLabel, wrapCss.truncate)}>
        {children}
      </span>
      {showIcon && (
        <ExternalLinkIcon
          className={classNames(linkCss.externalLinkIcon, iconClassName)}
        />
      )}
    </a>
  );
}
