import { ShopifySubscription } from "@redotech/redo-model/shopify-subscription/shopify-subscription";
import { Card } from "@redotech/redo-web/card";
import { DateInput, MaybeDate } from "@redotech/redo-web/date-picker";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import * as aiCss from "./ai.module.css";

export const supportAiForm = groupInput({
  aiFreeTrialDays: input<string>(),
  lastDayActive: input<MaybeDate>(),
  enabled: input<boolean>(),
  aiPricePerMonth: input<string>(),
  aiOveragePrice: input<string>(),
  aiTicketsCovered: input<string>(),
});

export type SupportAiForm = InputProvider.Form<typeof supportAiForm>;

export type SupportAiValue = InputProvider.Value<typeof supportAiForm>;

export const supportAiDefault: SupportAiValue = {
  aiFreeTrialDays: "0",
  lastDayActive: null,
  enabled: false,
  aiPricePerMonth: "0",
  aiOveragePrice: "0",
  aiTicketsCovered: "0",
};

export const SupportAiCard = memo(function SupportAiCard({
  input,
  shopifySubscription,
}: {
  input: SupportAiForm;
  shopifySubscription?: ShopifySubscription[];
}) {
  const {
    enabled,
    lastDayActive,
    aiFreeTrialDays,
    aiPricePerMonth,
    aiOveragePrice,
    aiTicketsCovered,
  } = input.inputs;

  const [freeTrialEndDate, setFreeTrialEndDate] = useState<MaybeDate>(null);

  useEffect(() => {
    if (
      (shopifySubscription?.length || 0) > 0 &&
      shopifySubscription?.[0].trialDays
    ) {
      const subscription = shopifySubscription[0];
      const trialEndDate = new Date(subscription.createdAt);
      trialEndDate.setDate(
        trialEndDate.getDate() + (subscription?.trialDays || 0),
      );
      setFreeTrialEndDate(trialEndDate);
    }
  }, []);

  useEffect(() => {
    if (freeTrialEndDate) {
      if (freeTrialEndDate.getTime() < Date.now()) {
        aiFreeTrialDays.setValue("0");
      } else {
        aiFreeTrialDays.setValue(
          Math.ceil(
            (freeTrialEndDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24),
          ).toString(),
        );
      }
    }
  }, [freeTrialEndDate]);

  return (
    <Card title="AI">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span8L, aiCss.cardItems)}>
          <FormSwitch input={enabled} label="Enabled">
            Enable AI features for Customer Support
          </FormSwitch>
        </div>
      </section>

      <h1>AI billing</h1>
      <div>
        If you input a custom plan here the merchant will need to accept billing
        on their end in Shopify. They will see a banner prompting them to do so.
      </div>
      <Text fontSize="sm">
        Free trial is only meant to be used once, so if you are trying to setup
        a second free trial for a merchant this won't work correctly and their
        subscription will need to be reset.
      </Text>
      <Text fontSize="md" fontWeight="semibold">
        Select free trial end date
      </Text>
      <DateInput value={freeTrialEndDate} valueChange={setFreeTrialEndDate} />
      <FormTextInput
        input={aiOveragePrice}
        label="Cost per conversation"
        max={2}
        min={0}
        prefix="$"
        step={0.01}
        type="number"
      />
      <FormTextInput
        input={aiPricePerMonth}
        label="Subscription fee (if applicable)"
        min={0}
        prefix="$"
        step={0.01}
        type="number"
      />
      <FormTextInput
        input={aiTicketsCovered}
        label="Free conversations per month (if applicable)"
        min={0}
        step={1}
        type="number"
      />
      <LabeledInput
        description="Setting this date will treat the merchant as if they are active on billing until the date specified. If a merchant cancels their subscription, this date will be set to their last available date, so be sure not to take it away for merchants who have canceled as they've already paid for that month."
        label="(NOT RECOMMENDED) Enable AI without billing"
      >
        <section className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
            <DateInput
              value={lastDayActive.value}
              valueChange={lastDayActive.setValue}
            />
          </div>
        </section>
      </LabeledInput>
    </Card>
  );
});
