import { AuditLogSchema } from "@redotech/redo-model/audit-log";
import { z } from "zod";

export const getAuditLog = {
  input: z.object({
    _id: z.string(),
  }),
  output: AuditLogSchema.nullable(),
};

export const getAuditLogs = {
  input: z.object({
    type: z.string().optional(),
    createdAt: z.tuple([z.date().nullable(), z.date().nullable()]).optional(),
    team: z.string().optional(),
    user: z.string().optional(),
    Resource: z.string().optional(),
    path: z.string().optional(),
    offset: z.number().optional(),
  }),
  output: z.object({
    auditLogs: z.array(AuditLogSchema),
    nextOffset: z.number().nullable(),
  }),
};
