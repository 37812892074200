import * as classNames from "classnames";
import { ReactElement, useEffect, useRef, useState } from "react";
import { RedoButtonSize, RedoButtonTheme } from "../buttons/redo-button";
import * as styles from "./redo-button-tab-group.module.css";

export const RedoButtonTabGroup = <T,>({
  value,
  onChange,
  options,
  size = RedoButtonSize.REGULAR,
  theme = RedoButtonTheme.NORMAL,
  disabled = false,
  fullWidth = true,
  className,
  onClick,
}: {
  value: T;
  onChange: (value: T) => void;
  options: { value: T; el: ReactElement }[];
  size?: RedoButtonSize;
  theme?: RedoButtonTheme;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, value: T) => void;
}) => {
  const [transition, setTransition] = useState(false);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!transition) {
      const timer = setTimeout(() => setTransition(true), 100);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [transition]);

  const activeIndex = options.findIndex((option) => option.value === value);

  const style = {
    "--tab-width": `${100 / options.length}%`,
    "--tab-position": `${activeIndex * 100}%`,
    "--options-length": options.length,
  } as React.CSSProperties;

  return (
    <div
      className={classNames(
        styles.redoButtonTabGroup,
        styles[size],
        styles[`theme${theme}`],
        disabled && styles.disabled,
        fullWidth && styles.fullWidth,
        className,
      )}
      ref={refContainer}
      style={style}
    >
      <div className={styles.activeIndicator} />
      <div className={styles.tabButtonsContainer}>
        {options.map((option, index) => (
          <button
            className={classNames(
              styles.tabButton,
              option.value === value && styles.active,
            )}
            disabled={disabled}
            key={index}
            onClick={(e) => {
              e.preventDefault();
              if (!disabled) {
                onChange(option.value);
                onClick?.(e, option.value);
              }
            }}
            type="button"
          >
            {option.el}
          </button>
        ))}
      </div>
    </div>
  );
};
