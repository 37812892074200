// extracted by mini-css-extract-plugin
export var grid = "_5-0";
export var span1 = "_5-2";
export var span10 = "_5-b";
export var span10L = "_5-z";
export var span10M = "_5-n";
export var span10Xl = "_5-1b";
export var span11 = "_5-c";
export var span11L = "_5-10";
export var span11M = "_5-o";
export var span11Xl = "_5-1c";
export var span12 = "_5-d";
export var span12L = "_5-11";
export var span12M = "_5-p";
export var span12Xl = "_5-1d";
export var span1L = "_5-q";
export var span1M = "_5-e";
export var span1Xl = "_5-12";
export var span2 = "_5-3";
export var span2L = "_5-r";
export var span2M = "_5-f";
export var span2Xl = "_5-13";
export var span3 = "_5-4";
export var span3L = "_5-s";
export var span3M = "_5-g";
export var span3Xl = "_5-14";
export var span4 = "_5-5";
export var span4L = "_5-t";
export var span4M = "_5-h";
export var span4Xl = "_5-15";
export var span5 = "_5-6";
export var span5L = "_5-u";
export var span5M = "_5-i";
export var span5Xl = "_5-16";
export var span6 = "_5-7";
export var span6L = "_5-v";
export var span6M = "_5-j";
export var span6Xl = "_5-17";
export var span7 = "_5-8";
export var span7L = "_5-w";
export var span7M = "_5-k";
export var span7Xl = "_5-18";
export var span8 = "_5-9";
export var span8L = "_5-x";
export var span8M = "_5-l";
export var span8Xl = "_5-19";
export var span9 = "_5-a";
export var span9L = "_5-y";
export var span9M = "_5-m";
export var span9Xl = "_5-1a";
export var stretch = "_5-1";