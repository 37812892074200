import { RouterOutletFade } from "@redotech/react-router-util/outlet-fade";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { PopulatedSplit } from "@redotech/redo-model/split";
import { Team } from "@redotech/redo-model/team";
import { BreadcrumbSlot, useBreadcrumb } from "@redotech/redo-web/breadcrumb";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import CheckCircle from "@redotech/redo-web/icon-old/check-circle.svg";
import ExternalLinkIcon from "@redotech/redo-web/icon-old/external-link.svg";
import { Action0PortalContext, Actions } from "@redotech/redo-web/page";
import { Tabs } from "@redotech/redo-web/tab";
import { createContext, memo, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import { RedoAdminClientContext } from "../client/context";
import { getSplits, getTeam } from "../client/team";
import { useMerchantAppLogin } from "./merchant-app";
import * as teamCss from "./team.module.css";

const enum TeamTab {
  CUSTOMER_SUPPORT = "customer-support",
  GENERAL = "general",
  RETURNS_PACKAGE_PROTECTION = "returns-package-protection",
  ORDERS = "orders",
  CUSTOMER_ACCOUNTS = "customer-accounts",
  MARKETING = "marketing",
}

const teamTabs = [
  TeamTab.GENERAL,
  TeamTab.CUSTOMER_SUPPORT,
  TeamTab.ORDERS,
  TeamTab.RETURNS_PACKAGE_PROTECTION,
  TeamTab.CUSTOMER_ACCOUNTS,
  TeamTab.MARKETING,
];

const TeamTabLabel = memo(function TeamTabLabel({ tab }: { tab: TeamTab }) {
  switch (tab) {
    case TeamTab.CUSTOMER_SUPPORT:
      return <>Customer Support</>;
    case TeamTab.GENERAL:
      return <>General</>;
    case TeamTab.ORDERS:
      return <>Orders</>;
    case TeamTab.RETURNS_PACKAGE_PROTECTION:
      return <>Returns / Package Protection</>;
    case TeamTab.CUSTOMER_ACCOUNTS:
      return <>Customer Accounts</>;
    case TeamTab.MARKETING:
      return <>Marketing</>;
  }
});

export const TeamContext = createContext<Team | undefined>(undefined);
export const LoadTeamContext = createContext<(() => void) | undefined>(
  undefined,
);

export const TeamPage = memo(function TeamPage({
  breadcrumb,
}: {
  breadcrumb: BreadcrumbSlot;
}) {
  const client = useRequiredContext(RedoAdminClientContext);

  const path = useResolvedPath("");
  const match = useMatch({
    path: `${path.pathname}/:child`,
    end: false,
  });

  const params = useParams();
  const teamId = params.teamId!;

  const [teamLoad, loadTeam] = useTriggerLoad((signal) =>
    getTeam(client, { teamId, signal }),
  );

  const [splitLoad, loadSplit] = useTriggerLoad((signal) =>
    getSplits(client, { teamId, signal }),
  );

  useBreadcrumb(breadcrumb, teamLoad.value?.name || "");

  useEffect(() => {
    loadTeam();
    loadSplit();
  }, [teamId]);

  const navigate = useNavigate();

  const actions0 = useContext(Action0PortalContext);

  if (!match || !teamLoad.value) {
    return null;
  }

  return (
    <>
      {actions0 &&
        teamLoad.value &&
        createPortal(
          <Actions show>
            <TreatmentsAction splits={splitLoad.value} />
            <MerchantAppAction team={teamLoad.value} />
          </Actions>,
          actions0,
        )}
      <div className={teamCss.container}>
        <Tabs
          options={teamTabs}
          tab={(option) => <TeamTabLabel tab={option} />}
          value={match.params.child as TeamTab}
          valueChange={(option) => navigate(option)}
        >
          <TeamContext.Provider value={teamLoad.value}>
            <div className={teamCss.container}>
              <LoadTeamContext.Provider value={loadTeam}>
                <RouterOutletFade />
              </LoadTeamContext.Provider>
            </div>
          </TeamContext.Provider>
        </Tabs>
      </div>
    </>
  );
});

const MerchantAppAction = memo(function MerchantAppAction({
  team,
}: {
  team: Team;
}) {
  const merchantAppLogin = useMerchantAppLogin();

  const handleClick = useHandler(() => {
    merchantAppLogin(team._id);
  });

  return (
    <Button
      className={teamCss.actionButton}
      onClick={handleClick}
      theme={ButtonTheme.OUTLINED}
    >
      <span>Merchant Dashboard</span>{" "}
      <span className={teamCss.icon}>
        <ExternalLinkIcon />
      </span>
    </Button>
  );
});

const TreatmentsAction = memo(function TreatmentsAction({
  splits,
}: {
  splits: PopulatedSplit[] | undefined;
}) {
  const navigate = useNavigate();

  const hasActiveSplit = splits?.some((split) => split.active);

  return (
    <Button
      className={teamCss.actionButton}
      onClick={() => navigate("split")}
      theme={ButtonTheme.OUTLINED}
    >
      {hasActiveSplit && <CheckCircle className={teamCss.splitStatus} />}
      <span>A/B Testing</span>
    </Button>
  );
});
