import { Money } from "@redotech/money";
import { Equal, booleanEqual, objectEqual } from "@redotech/util/equal";

export enum PaymentModel {
  CUSTOMER_PAID = "customer_paid",
  MERCHANT_PAID = "merchant_paid",
}

export interface Coverage {
  exchange: boolean;
  storeCredit: boolean;
  refund: boolean;
}

export const coverageEqual: Equal<Coverage> = objectEqual({
  exchange: booleanEqual,
  refund: booleanEqual,
  storeCredit: booleanEqual,
});

const paymentModelWeights: { [K in PaymentModel]: number } = {
  customer_paid: 1,
  merchant_paid: 0.9,
};

export function paymentModelMultiplier(paymentModel: PaymentModel) {
  return paymentModelWeights[paymentModel];
}

export function pricePerOrder(
  coverage: Coverage,
  paymentModel: PaymentModel,
  base: Money,
): Money {
  const paymentModelWeight = paymentModelMultiplier(paymentModel);
  return new Money(
    +(+base.amount * paymentModelWeight).toFixed(2),
    base.currency,
  );
}
