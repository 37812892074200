import { useRequiredContext } from "@redotech/react-util/context";
import { newWindow } from "@redotech/web-util/window";
import { UserContext } from "../app/user";
import { RedoAdminClientContext } from "../client/context";
import { createTeamAuth } from "../client/team";
import { REDO_MERCHANT_APP_URL } from "../config";

export function useMerchantAppLogin(): (teamId: string) => void {
  const client = useRequiredContext(RedoAdminClientContext);
  const user = useRequiredContext(UserContext);

  return (teamId) => {
    newWindow(async () => {
      try {
        const auth = await createTeamAuth(client, {
          name: user.name,
          teamId,
        });
        const url = new URL("/login/token", REDO_MERCHANT_APP_URL);
        url.searchParams.set("token", auth.authToken);
        return url.toString();
      } catch (e) {
        alert((e as Error).message);
        throw e;
      }
    });
  };
}
