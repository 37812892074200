import { useTriggerLoad } from "@redotech/react-util/load";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { RedoAdminClientContext } from "../../../client/context";
import { isSyncInProgress, syncTrackers } from "../../../client/orders";
import { LoadTeamContext, TeamContext } from "../../team";

export const orderTrackingGeneralForm = groupInput({
  enabled: input<boolean>(),
  enabledCommentsold: input<boolean>(),
  returnTrackingEnabled: input<boolean>(),
  createTrackers: input<boolean>(),
  showPDPDeliveryEstimate: input<boolean>(),
  useRedoTrackingUrl: input<boolean>(),
  stalledDaysFulfillment: input<string>(),
  disableDefaultEmails: input<boolean>(),
  excludeTrackMyOrderClicks: input<boolean>(),
});

export type OrderTrackingGeneralForm = InputProvider.Form<
  typeof orderTrackingGeneralForm
>;

export type OrderTrackingGeneralValue = InputProvider.Value<
  typeof orderTrackingGeneralForm
>;

export const orderTrackingGeneralDefault: OrderTrackingGeneralValue = {
  enabled: false,
  enabledCommentsold: false,
  returnTrackingEnabled: false,
  createTrackers: false,
  showPDPDeliveryEstimate: false,
  useRedoTrackingUrl: false,
  stalledDaysFulfillment: "5",
  disableDefaultEmails: false,
  excludeTrackMyOrderClicks: false,
};

export const OrderTrackingGeneralCard = memo(function OrderTrackingGeneralCard({
  input,
}: {
  input: OrderTrackingGeneralForm;
}) {
  const {
    enabled,
    enabledCommentsold,
    returnTrackingEnabled,
    createTrackers,
    showPDPDeliveryEstimate,
    useRedoTrackingUrl,
    disableDefaultEmails,
    excludeTrackMyOrderClicks,
  } = input.inputs;
  useEffect(() => {
    if (enabled.value) {
      createTrackers.setValue(true);
    }
  }, [enabled.value]);

  const team = useContext(TeamContext);
  const mostRecentSync = team?.settings.orderTracking?.mostRecentSync;

  const canCreateTrackers =
    team?.settings?.orderTracking?.createTrackers && input.value.createTrackers;

  return (
    <Card title="Order Tracking">
      <section className={gridCss.grid}>
        <div className={gridCss.span6L}>
          <FormSwitch input={enabled} label="Order Tracking Enabled">
            Enable Order Tracking product
          </FormSwitch>
        </div>
        <div className={gridCss.span6L}>
          <FormSwitch
            input={returnTrackingEnabled}
            label="Return Tracking Enabled"
          >
            Enable Return Tracking product
          </FormSwitch>
        </div>
        <div className={gridCss.span6L}>
          <FormSwitch input={createTrackers} label="Create Trackers">
            Will not enable order tracking but will create trackers for all
            future orders. The last 30 days will be synced within 2 hours, or
            you can sync immediately below.
          </FormSwitch>
          <Flex dir="column" gap="xs" mt="lg">
            {canCreateTrackers ? (
              <>
                <ManualSyncTrackersButton />
                <Text fontWeight="bold" mt="lg">
                  Most recent sync:
                </Text>
                <div>
                  {mostRecentSync ? (
                    <>
                      <Text>
                        Date:{" "}
                        {getDateTimeString(new Date(mostRecentSync.syncDate))}
                      </Text>
                      <Text>
                        Fulfillments attempted:{" "}
                        {mostRecentSync.fulfillmentsAttempted}
                      </Text>
                      <Text>
                        Fulfillments failed: {mostRecentSync.fulfillmentsFailed}
                      </Text>
                      <Text>
                        Orders fully synced: {mostRecentSync.ordersFullySynced}
                      </Text>
                      <Text>
                        Orders not fully synced:{" "}
                        {mostRecentSync.ordersNotFullySynced}
                      </Text>
                    </>
                  ) : (
                    "Never synced"
                  )}
                </div>
              </>
            ) : (
              <Text>
                To sync trackers, 1) enable <strong>create trackers</strong>, 2){" "}
                <strong>save</strong>.
              </Text>
            )}
          </Flex>
        </div>
        <div className={gridCss.span6L}>
          <FormSwitch
            input={disableDefaultEmails}
            label="Disable default emails"
          >
            Enabling this will disable the default legacy emails and will send
            the emails configured by return flows in Redo.
          </FormSwitch>
        </div>
        <div className={gridCss.span6L}>
          <FormSwitch
            input={showPDPDeliveryEstimate}
            label="Show PDP Delivery Estimate"
          />
        </div>
        <div className={gridCss.span6L}>
          <FormSwitch
            input={excludeTrackMyOrderClicks}
            label="Exclude Track My Order Clicks"
          >
            Exclude "Track My Order" email clicks from upsell analytics
          </FormSwitch>
        </div>
        <div className={gridCss.span6L}>
          <FormSwitch
            input={enabledCommentsold}
            label="Commentsold Order Tracking Enabled"
          >
            Enable order tracking for Commentsold orders
          </FormSwitch>
        </div>
        <div className={gridCss.span6L}>
          <FormSwitch input={useRedoTrackingUrl} label="Use Redo tracking URL">
            <b>
              Do NOT enable unless the merchant is still sending tracking emails
              through Shopify.
            </b>{" "}
            If enabled, we will replace the tracking URL from the carrier with a
            deep link to Redo's tracking page
          </FormSwitch>
        </div>
        <Flex className={gridCss.span6L}>
          <FormTextInput
            input={input.inputs.stalledDaysFulfillment}
            label="Stalled Days for Fulfillment"
            min={0}
            step={1}
            type="number"
          />
        </Flex>
      </section>
    </Card>
  );
});

const POLL_FREQUENCY_MS = 5000;

const ManualSyncTrackersButton = memo(function ManualSyncTrackersButton() {
  const team = useContext(TeamContext);
  const client = useContext(RedoAdminClientContext);
  const loadTeam = useContext(LoadTeamContext);

  const [syncInProgress, setSyncInProgress] = useState(false);
  const pollingInProgress = useRef(false);

  const pollUntilFinished: () => void = () => {
    if (pollingInProgress.current) return;
    pollingInProgress.current = true;
    setTimeout(refreshInProgressStatus, POLL_FREQUENCY_MS);
  };

  useEffect(() => {
    if (!client || !team) return;
    isSyncInProgress(client, { teamId: team?._id })
      .then((inProgress) => {
        setSyncInProgress(inProgress);
        if (inProgress) {
          pollUntilFinished();
        }
      })
      .catch(() => console.error("Failed to check sync status"));
  }, [team]);

  const [_, doStartSync] = useTriggerLoad(async (signal) => {
    if (!client || !team) return;
    setSyncInProgress(true);
    await syncTrackers(client, { teamId: team._id, signal });
    pollUntilFinished();
  });

  if (!team || !client) {
    return null;
  }

  const refreshInProgressStatus = async () => {
    const inProgress = await isSyncInProgress(client, { teamId: team._id });
    setSyncInProgress(inProgress);
    if (inProgress) {
      setTimeout(refreshInProgressStatus, POLL_FREQUENCY_MS);
    } else {
      pollingInProgress.current = false;
      loadTeam?.();
    }
  };

  return (
    <Flex dir="column" gap="lg">
      <Button
        disabled={syncInProgress}
        onClick={() => doStartSync()}
        pending={syncInProgress}
        theme={ButtonTheme.PRIMARY}
      >
        Sync Unsynced Trackers
      </Button>
      <Text fontSize="xs" textColor="tertiary">
        This will attempt to attach trackers to fulfillments created within the
        last 30 days that do not yet have one. If a fulfillment fails to sync
        several times, it will be skipped moving forward. An engineer can force
        retry these failed syncs, but it is unlikely to do anything.
      </Text>
      {syncInProgress && <Text textColor="warning">Sync in progress</Text>}
    </Flex>
  );
});
