import { genericMemo } from "@redotech/react-util/component";
import { LoadState, useTriggerLoad } from "@redotech/react-util/load";
import { Input } from "@redotech/ui/form";
import { ReactNode, useContext, useId } from "react";
import { createPortal } from "react-dom";
import { alertOnFailure } from "./alert";
import { Button, ButtonTheme } from "./button";
import * as cardCss from "./card.module.css";
import { Form } from "./form";
import { ActionPortalContext, Actions } from "./page";

export interface PageFormSave {
  (signal: AbortSignal): Promise<void>;
}

export const PageForm = genericMemo(function PageForm<T>({
  children,
  initial,
  input,
  save,
  additionalActions,
}: {
  children: ReactNode | ReactNode[];
  initial: T;
  input: Input<T>;
  save: PageFormSave;
  additionalActions?: ReactNode;
}) {
  const actionsPortal = useContext(ActionPortalContext);
  const id = useId();

  const [saveLoad, doSave] = useTriggerLoad((signal) =>
    alertOnFailure("Saving failed, please check your inputs")(async () => {
      await save(signal);
      return true as const;
    }),
  );

  return (
    <>
      {actionsPortal &&
        createPortal(
          <Actions show={input.changed}>
            <PageFormActions
              additionalActions={additionalActions}
              form={id}
              input={input}
              saveLoad={saveLoad}
            />
          </Actions>,
          actionsPortal,
        )}
      <Form id={id} initial={initial} input={input} onSubmit={doSave}>
        {children}
      </Form>
    </>
  );
});

const PageFormActions = genericMemo(function PageFormActions<T>({
  form,
  input,
  saveLoad,
  additionalActions,
}: {
  form: string;
  input: Input<T>;
  saveLoad: LoadState<true | undefined>;
  additionalActions?: ReactNode;
}) {
  return (
    <div className={cardCss.buttonBar}>
      <Button
        disabled={!!input.allErrors.length || !input.changed}
        form={form}
        pending={saveLoad.pending}
        theme={ButtonTheme.PRIMARY}
        type="submit"
      >
        Save
      </Button>
      <Button
        disabled={saveLoad.pending || !input.changed}
        form={form}
        theme={ButtonTheme.OUTLINED}
        type="reset"
      >
        Cancel
      </Button>
      {additionalActions}
    </div>
  );
});
