import { fullCoverage, getReturnType } from "./checkbox";
import type { Coverage } from "./coverage";
import type { ToggleFields } from "./team";

export const DEFAULT_IMAGE_URL = "https://assets.getredo.com/redo-shield.png";
export const DEFAULT_ICON_COLOR = "#000000";
export const DEFAULT_ICON_BACKGROUND_COLOR = "#FFFFFF";

// TODO: add dynamic coverage text
export function defaultToggleTextOptions(coverage: Coverage) {
  const returnType = getReturnType(coverage);

  let coverageText = returnType !== fullCoverage ? ` for ${returnType}` : "";
  coverageText = coverageText.toLowerCase();

  return {
    returnToggle: {
      titleText: "Free Unlimited Returns",
      positiveSubtext: `Get free returns${coverageText} on your order`,
      negativeSubtext: "Currently liable for return shipping",
    },
    packageProtectionToggle: {
      titleText: "Package Protection",
      positiveSubtext:
        "Your order is protected from loss or damage during shipping",
      negativeSubtext:
        "By deselecting, your order is not protected from loss or damage during shipping",
    },
    bothProductToggle: {
      titleText: "Free Returns + Package Protection",
      positiveSubtext: `Free returns${coverageText} and protection from loss or damage`,
      negativeSubtext:
        "By deselecting, you're liable for return shipping and package loss or damage",
    },
  };
}

export function makeEmptyToggleTexts() {
  return {
    returnToggle: {
      titleText: "",
      positiveSubtext: "",
      negativeSubtext: "",
    },
    packageProtectionToggle: {
      titleText: "",
      positiveSubtext: "",
      negativeSubtext: "",
    },
    bothProductToggle: {
      titleText: "",
      positiveSubtext: "",
      negativeSubtext: "",
    },
  };
}

export function fillEmptyFieldsWithDefault(
  toggleField: ToggleFields | undefined,
  defaultFields: ToggleFields,
) {
  if (!toggleField) return defaultFields;

  toggleField.titleText = toggleField.titleText || defaultFields.titleText;
  toggleField.positiveSubtext =
    toggleField.positiveSubtext || defaultFields.positiveSubtext;
  toggleField.negativeSubtext =
    toggleField.negativeSubtext || defaultFields.negativeSubtext;

  return toggleField;
}
