// extracted by mini-css-extract-plugin
export var container = "_l-0";
export var error = "_l-9";
export var label = "_l-5";
export var light = "_l-6";
export var md = "_l-3";
export var sm = "_l-2";
export var thin = "_l-8";
export var thinBold = "_l-7";
export var vertical = "_l-4";
export var xs = "_l-1";