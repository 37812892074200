import { WeightByZoneRateTable } from "@redotech/redo-model/rate-table/rate-table";

/*
 *  Our rate table input is a grid that looks like this (z = zone, m = max weight, x = rate)
 *     z z z z z z
 *   m x x x x x x
 *   m x x x x x x
 *   m x x x x x x
 *
 *  These functions handle the logic for transforming data to and from this grid from the RateTable model
 */

// The rates are the x's in the grid above - get them from the grid
export function tableDataToRateData(tableData: string[][]): string[][] {
  const data = [...tableData];
  const withoutFirstRow = data.slice(1);
  const withoutFirstColumn = withoutFirstRow.map((row) => row.slice(1));
  return withoutFirstColumn;
}

// The zones are the z's in the grid above - get them from the grid
export function tableDataToZones(tableData: string[][]): string[] {
  const firstRow = tableData.at(0);
  const zones = firstRow?.slice(1);
  return zones ?? [];
}

// The min weights are the m's in the grid above - get them from the grid
export function tableDataToMinMaxValues(tableData: string[][]): {
  minWeight: number;
  maxWeight: number;
}[] {
  // Get rid of the zones row
  const withoutFirstRow = tableData.slice(1);
  const minMaxValues = withoutFirstRow.map((row, index) => {
    const prevRow = withoutFirstRow[index - 1];
    const prevMaxWeight = Number(prevRow?.at(0) ?? 0);
    const maxWeight = Number(row.at(0) ?? 0);
    return {
      minWeight: prevMaxWeight,
      maxWeight,
    };
  });

  return minMaxValues;
}

// The max weights are the m's in the grid above - get them from the grid
export function tableDataToMaxValues(tableData: string[][]): string[] {
  return tableData
    .map((row) => row.at(0))
    .filter((value) => value !== undefined);
}

// We only support weight on the y-axis and zone on the x-axis for now
export function rateTableToTableData(
  rateTable: WeightByZoneRateTable,
): string[][] {
  const maxValues = rateTable.yAxis.values.map((value) =>
    value.maxWeight.toString(),
  );

  const withMaxValues = rateTable.data.map((row, rowIndex) => {
    return [
      maxValues.at(rowIndex) ?? "",
      ...row.map((cell) => cell.toString()),
    ];
  });

  const zones = rateTable.xAxis.values;
  // The leftmost cells in the "zone" row are empty
  const zonesWithEmptyCells = ["0", ...zones];
  const zonesAndRates = [zonesWithEmptyCells, ...withMaxValues];

  return zonesAndRates;
}
