import { CoverageMonitor as CoverageMonitorType } from "@redotech/redo-model/coverage-monitor";
import { Button, ButtonBorder, ButtonTheme } from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import React, { memo } from "react";
import * as teamCss from "../../team.module.css";

export const CoverageMonitoringCard = memo(function CoverageMonitoringCard({
  coverageMonitor,
  onButtonClick,
}: {
  coverageMonitor: CoverageMonitorType;
  onButtonClick: () => void;
}) {
  if (!coverageMonitor?.current) {
    return <> </>;
  }

  const unprotectedBase = coverageMonitor?.baseline?.[0];
  const protectedBase = coverageMonitor?.baseline?.[1];

  // let currentValues = Object.values(coverageMonitor.current).filter(Array.isArray);
  const currentValuesTitles: string[] = [];
  const currentValuesMessages: [string][] = [];
  Object.entries(coverageMonitor.current).forEach(([key, value]) => {
    if (value) {
      currentValuesTitles.push("Most Recent " + key + " Orders");
      let currentPercentage: string | number = "Uncalculated";
      const protectedCurrent = value[1];
      const unprotectedCurrent = value[0];
      if (protectedCurrent && unprotectedCurrent) {
        currentPercentage =
          protectedCurrent / (protectedCurrent + unprotectedCurrent);
      }
      currentValuesMessages.push(["Percent Attachment: " + currentPercentage]);
    }
  });

  let historicalPercentage: string | number = "Uncalculated";
  if (protectedBase && unprotectedBase) {
    historicalPercentage = protectedBase / (protectedBase + unprotectedBase);
  }

  const alertValue = coverageMonitor?.alertAt;
  let formattedAlertValue = "";
  if (alertValue) {
    formattedAlertValue = getDateTimeString(new Date(alertValue));
  }

  let alertMessage = "";
  if (alertValue) {
    alertMessage = "Alert Activated At: " + formattedAlertValue;
  } else {
    alertMessage = "Alert Off";
  }

  return (
    <Card title="Coverage Monitoring">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <dl className={teamCss.table}>
            <dt className={teamCss.tableHeader}>Alert</dt>
            <dd className={teamCss.tableCell}>{alertMessage}</dd>
            <dt className={teamCss.tableHeader}>Historical</dt>
            <dd className={teamCss.tableCell}>
              {coverageMonitor?.baseline &&
                "Percent Attachment: " + historicalPercentage}
            </dd>
            {currentValuesTitles.map((title, index) => (
              <React.Fragment key={title}>
                <dt className={teamCss.tableHeader}>{title}</dt>
                <dd className={teamCss.tableCell}>
                  {currentValuesMessages[index].join("\n")}
                </dd>
              </React.Fragment>
            ))}
          </dl>
        </div>
        <div className={gridCss.span12}>
          <Button
            border={ButtonBorder.DARK}
            onClick={() => {
              onButtonClick();
            }}
            theme={ButtonTheme.OUTLINED}
          >
            Reset Alert
          </Button>
        </div>
      </section>
      {/* <section className={gridCss.grid}>
            <pre>{JSON.stringify(coverageMonitor, null, 2)}</pre>
          </section> */}
    </Card>
  );
});
