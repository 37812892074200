import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { ServiceLevel } from "@redotech/redo-model/outbound-labels";
import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { InputProvider, groupInput, input, listInput } from "@redotech/ui/form";
import { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminClientContext } from "../../../client/context";
import { getServiceLevels } from "../../../client/order-tracking";

const serviceLevelMappingsForm = groupInput({
  merchantServiceLevel: input<string>(),
  redoServiceLevel: input<ServiceLevel[]>({
    equal: (a, b) => {
      return (
        a.length === b.length &&
        a.every(
          (v, i) => v.carrier === b[i].carrier && v.service === b[i].service,
        )
      );
    },
  }),
});

const serviceLevelMappingsDefault = (): InputProvider.Value<
  typeof serviceLevelMappingsForm
> => ({
  merchantServiceLevel: "",
  redoServiceLevel: [],
});

export const outboundLabelForm = groupInput({
  enabled: input<boolean>(),
  serviceLevelMappings: listInput(
    () => serviceLevelMappingsForm,
    serviceLevelMappingsDefault,
    (i) => i.merchantServiceLevel,
  ),
});

export type OutboundLabelForm = InputProvider.Form<typeof outboundLabelForm>;

export type OutboundLabelValue = InputProvider.Value<typeof outboundLabelForm>;

export const outboundLabelDefault: OutboundLabelValue = {
  enabled: false,
  serviceLevelMappings: [],
};

export const OutboundLabelsCard = memo(function OutboundLabelsCard({
  input,
}: {
  input: OutboundLabelForm;
}) {
  const { enabled, serviceLevelMappings } = input.inputs;
  const client = useRequiredContext(RedoAdminClientContext);
  const params = useParams();
  const teamId = params.teamId!;

  const [serviceLevelLoad, doServiceLevelLoad] = useTriggerLoad(
    async (signal) => {
      const serviceLevels = await getServiceLevels(client, {
        teamId,
        signal,
      });
      serviceLevelMappings.setValue(
        serviceLevels.map((serviceLevel: string) => {
          return {
            merchantServiceLevel: serviceLevel,
            redoServiceLevel: [],
          };
        }),
      );
      return serviceLevels;
    },
  );

  useEffect(() => {
    if (
      enabled.value &&
      !serviceLevelLoad.value &&
      !serviceLevelMappings.value.length
    ) {
      doServiceLevelLoad();
    }
  }, [enabled.value]);

  return (
    <Card title="Outbound Labels">
      <section className={gridCss.grid}>
        <div className={gridCss.span12L}>
          <FormSwitch input={enabled} label="Enabled">
            Enable Outbound Labels
          </FormSwitch>
          <hr />
        </div>
      </section>
    </Card>
  );
});
