import { usePrompt } from "@redotech/react-router-util/prompt";
import { genericMemo } from "@redotech/react-util/component";
import { Input } from "@redotech/ui/form";
import { FormEventHandler, ReactNode } from "react";
import * as formCss from "./form.module.css";

export const Form = genericMemo(function Form<T>({
  children,
  id,
  input,
  initial,
  onSubmit,
}: {
  id: string;
  initial: T;
  input: Input<T>;
  onSubmit?(): void;
  children: ReactNode | ReactNode[];
}) {
  usePrompt(
    input.changed
      ? "You have unsaved changes. Would you like to discard these changes?"
      : null,
  );

  const handleReset: FormEventHandler = (e) => {
    e.preventDefault();
    input.setValue(initial);
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    onSubmit && onSubmit();
  };

  return (
    <form
      className={formCss.form}
      id={id}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
});
