import { z } from "zod";

export const createCommentSoldTeam = {
  input: z.object({
    name: z.string(),
    storeUrl: z.string(),
    email: z.string(),
    addressName: z.string(),
    street1: z.string(),
    street2: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    country: z.string(),
    country_name: z.string(),
    phone: z.string(),
  }),
  output: z.object({
    success: z.boolean(),
    message: z.nullable(z.string()),
    team: z.string().nullable(),
  }),
};
