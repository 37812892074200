export type Offering = {
  title: string;
  id: string;
  collectionHandle: string;
  price: {
    currency: string;
    amount: number;
  };
};

export type Collection = {
  title: string;
  handle: string;
};

export type Selections = {
  [variantId: string]: {
    collectionHandle: string;
    timeId: string;
  };
};

export const CART_ATTRIBUTE_KEY = "extended-warranty-selections";
export const SHOP_METAFIELD_KEY = "extended-warranty-settings";
export const SHOP_METAFIELD_NAMESPACE = "$app";
export const NO_COVERAGE_TIME_ID = "no_coverage";
export const DEFAULT_PRODUCT_TITLE = "Redo Extended Warranty";

export const getCheapestOffering = (
  offerings: Offering[],
): Offering | undefined => {
  return offerings
    .filter((o) => o.id !== NO_COVERAGE_TIME_ID)
    .reduce((cheapest, current) => {
      if (!cheapest || !current) {
        return current;
      }
      return current.price.amount < cheapest.price.amount ? current : cheapest;
    }, offerings[0]);
};
