import type { ExchangeRates } from "@redotech/money/exchange-rates";
import { OpenExchangeRatesCurrency } from "@redotech/money/openexchangerates.org/currencies";
import { RedoAdminClient } from ".";

/**
 * GET /money/exchange-rates
 */
export async function getExchangeRates(
  client: RedoAdminClient,
): Promise<ExchangeRates> {
  const response = await client.client.get<ExchangeRates>(
    "money/exchange-rates",
    { headers: client.authorization() },
  );
  return response.data;
}

export async function getExchangeRate<
  From extends OpenExchangeRatesCurrency,
  To extends OpenExchangeRatesCurrency,
>(
  client: RedoAdminClient,
  from: From,
  to: To,
): Promise<ExchangeRates<From, To>> {
  const response = await client.client.get<ExchangeRates<From, To>>(
    "money/exchange-rates",
    { headers: client.authorization(), params: { from, to } },
  );
  return response.data;
}
