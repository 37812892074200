import {
  AxisKind,
  RateTable,
  WeightUnit,
} from "@redotech/redo-model/rate-table/rate-table";
import {
  RateTableCarrier,
  RateTableServiceLevel,
} from "@redotech/redo-model/rate-table/rate-table-mapping";

export const defaultNewRateTable = (
  carrier: RateTableCarrier,
  serviceLevel: RateTableServiceLevel,
): RateTable => ({
  carrier,
  serviceLevel,
  data: [
    ["0", "0", "0", "0", "0", "0", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0", "0"],
  ],
  xAxis: {
    values: ["1", "2", "3", "4", "5", "6", "7", "8"],
    kind: AxisKind.ZONE,
  },
  yAxis: {
    values: [
      { minWeight: 0, maxWeight: 1 },
      { minWeight: 1, maxWeight: 2 },
      { minWeight: 2, maxWeight: 3 },
      { minWeight: 3, maxWeight: 4 },
      { minWeight: 4, maxWeight: 5 },
      { minWeight: 5, maxWeight: 6 },
      { minWeight: 6, maxWeight: 7 },
      { minWeight: 7, maxWeight: 8 },
    ],
    kind: AxisKind.WEIGHT,
    units: WeightUnit.LB,
  },
  defaultValue: "5",
});
