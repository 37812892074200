// extracted by mini-css-extract-plugin
export var box = "_1o-1";
export var checked = "_1o-5";
export var descriptionSpacer = "_1o-9";
export var disabled = "_1o-4";
export var icon = "_1o-6";
export var input = "_1o-0";
export var labelSpacer = "_1o-8";
export var medium = "_1o-3";
export var small = "_1o-2";
export var text = "_1o-7";