// extracted by mini-css-extract-plugin
export var active = "_19-a";
export var activeIndicator = "_19-9";
export var disabled = "_19-b";
export var fullWidth = "_19-2";
export var lg = "_19-7";
export var md = "_19-6";
export var redoButtonTabGroup = "_19-0";
export var sm = "_19-5";
export var tabButton = "_19-4";
export var tabButtonsContainer = "_19-1";
export var themeDestructive = "_19-d";
export var themeNormal = "_19-c";
export var themeSuccess = "_19-e";
export var xl = "_19-8";
export var xs = "_19-3";