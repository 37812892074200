import { z } from "zod";

export enum DeliveryFilterType {
  // Filter out all redo options except the cheapest
  ONLY_CHEAPEST_REDO = "only-cheapest-redo",
  // Filter out all non-redo options
  ONLY_REDO = "only-redo",
  // Filter out all redo options (only useful for tests)
  NO_REDO = "no-redo",
  // Filter out all non-redo options except the cheapest one
  ONLY_CHEAPEST_NON_REDO = "only-cheapest-non-redo",
}

export enum DeliverySortType {
  // Cheapest Redo option, then sort all others by price
  REDO_CHEAPEST_FIRST = "redo-cheapest-first",
  // Show all Redo options before any other shipping options
  REDO_FIRST = "redo-first",
  // Sort all shipping options by price, cheapest first
  // Is essentially "toggle off"
  CHEAPEST_FIRST = "cheapest-first",
}

const DeliveryFilterTypeEnum = z.nativeEnum(DeliveryFilterType);

const DeliverySortTypeEnum = z.nativeEnum(DeliverySortType);

const WeightConditionSchema = z.object({
  type: z.literal("weight"),
  minGrams: z.number(),
  maxGrams: z.number(),
});

const PriceConditionSchema = z.object({
  type: z.literal("price"),
  minPrice: z.number(),
  maxPrice: z.number(),
  currency: z.string(),
});

export type PriceCondition = z.infer<typeof PriceConditionSchema>;

const RateConditionSchema = z.union([
  WeightConditionSchema,
  PriceConditionSchema,
]);

const ShippingRateLocationSchema = z.object({
  country: z.string(),
  provinces: z.array(z.string()),
});

export type ShippingRateLocation = z.infer<typeof ShippingRateLocationSchema>;
const RatesSchema = z.object({
  conditions: z.array(RateConditionSchema),
  currency: z.string(),
  merchantPaidCoverageTypes: z
    .object({
      return: z.boolean().optional(),
      packageProtection: z.boolean().optional(),
    })
    .optional(),
  price: z.string(),
});

const ShippingRateTableZodSchema = z.object({
  name: z.string(),
  description: z.string(),
  code: z.string(),
  type: z.literal("fixed"),
  originLocations: z.array(ShippingRateLocationSchema),
  destinationLocations: z.array(ShippingRateLocationSchema),
  coverageTypes: z.object({
    return: z.boolean().optional(),
    packageProtection: z.boolean().optional(),
  }),
  rates: z.array(RatesSchema),
});

export type ShippingRateTable = z.infer<typeof ShippingRateTableZodSchema>;

const FilterSort = z.object({
  filterType: DeliveryFilterTypeEnum.optional(),
  sortType: DeliverySortTypeEnum.optional(),
});

export const ShippingCoverageZodSchema = z.object({
  enabled: z.boolean(),
  rateTables: z.array(ShippingRateTableZodSchema),
  deliverySortType: DeliverySortTypeEnum.optional(),
  deliveryFilterType: DeliveryFilterTypeEnum.optional(),
  optIn: FilterSort.optional(),
  optOut: FilterSort.optional(),
});

export type ShippingCoverage = z.infer<typeof ShippingCoverageZodSchema>;
