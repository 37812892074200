import { z } from "zod";
import { zExt } from "./common/zod-util";

const BaseAuditLogSchema = z.object({
  _id: zExt.objectId(),
  type: z.string(),
  team: zExt.objectId().optional(),
  user: z.string().optional(),
  Resource: z.string().optional(),
  access: z.any().optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const SettingsAuditLogSchema = BaseAuditLogSchema.merge(
  z.object({
    type: z.literal("setting"),
    path: z.string().optional(),
    prev: z.any().optional(),
    next: z.any().optional(),
  }),
);

export type SettingsAuditLog = z.infer<typeof SettingsAuditLogSchema>;

export const AuditLogSchema = z.union([
  SettingsAuditLogSchema,
  // Add more possible audit log types here
  // This gives us a discriminated union based on "type"
  z.never(),
]);

export type AuditLog = z.infer<typeof AuditLogSchema>;
