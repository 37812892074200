import { z } from "zod";
import {
  FedexServiceLevel,
  RateTableCarrier,
  USPSServiceLevel,
} from "./rate-table-mapping";

export enum AxisKind {
  ZONE = "zone",
  WEIGHT = "weight",
}

export enum WeightUnit {
  OZ = "OZ",
  LB = "LB",
}

const zoneAxisSchema = z.object({
  kind: z.literal(AxisKind.ZONE),
  values: z.array(z.string()),
});

const weightAxisSchema = z.object({
  kind: z.literal(AxisKind.WEIGHT),
  units: z.nativeEnum(WeightUnit),
  values: z.array(
    z.object({
      minWeight: z.number(),
      maxWeight: z.number(),
    }),
  ),
});

const serviceLevelsSchema = z.union([
  z.nativeEnum(USPSServiceLevel),
  z.nativeEnum(FedexServiceLevel),
]);

const weightByZoneSchema = z.object({
  carrier: z.nativeEnum(RateTableCarrier),
  serviceLevel: serviceLevelsSchema,
  xAxis: zoneAxisSchema,
  yAxis: weightAxisSchema,
  data: z.array(z.array(z.string())),
  defaultValue: z.string(),
});

const zoneByWeightSchema = z.object({
  carrier: z.nativeEnum(RateTableCarrier),
  serviceLevel: serviceLevelsSchema,
  xAxis: weightAxisSchema,
  yAxis: zoneAxisSchema,
  data: z.array(z.array(z.string())),
  defaultValue: z.string(),
});

export const rateTableSchema = z
  .union([weightByZoneSchema, zoneByWeightSchema])
  .refine(
    ({ data, yAxis }) => data.length === yAxis.values.length,
    "Invalid number of rows",
  )
  .refine(
    ({ data, xAxis }) =>
      data.every((row) => row.length === xAxis.values.length),
    "Invalid number of columns",
  );

export type WeightByZoneRateTable = z.infer<typeof weightByZoneSchema>;

export function isWeightByZoneRateTable(
  table: RateTable,
): table is WeightByZoneRateTable {
  return (
    table.xAxis.kind === AxisKind.ZONE && table.yAxis.kind === AxisKind.WEIGHT
  );
}

const axisSchema = z.union([zoneAxisSchema, weightAxisSchema]);

export type AxisDef = z.infer<typeof axisSchema>;
export type RateTable = z.infer<typeof rateTableSchema>;

export type ShipmentData = {
  [AxisKind.ZONE]: string;
  [AxisKind.WEIGHT]: {
    unit: WeightUnit;
    value: number;
  };
};
