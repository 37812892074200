import { assertNever } from "@redotech/util/type";

export enum RateTableCarrier {
  USPS = "USPS",
  FEDEX = "FedEx",
}

export enum USPSServiceLevel {
  Default = "Default",
  Ground = "Ground",
}

export enum FedexServiceLevel {
  Default = "Default",
  SmartPost = "Smart Post",
}

export type RateTableServiceLevel = USPSServiceLevel | FedexServiceLevel;

type ServiceLevels = {
  [RateTableCarrier.USPS]: USPSServiceLevel;
  [RateTableCarrier.FEDEX]: FedexServiceLevel;
};

export const RateTableServiceLevelsMapping: Record<
  RateTableCarrier,
  ServiceLevels[RateTableCarrier][]
> = {
  [RateTableCarrier.USPS]: Object.values(USPSServiceLevel),
  [RateTableCarrier.FEDEX]: Object.values(FedexServiceLevel),
};

export function defaultCarrierServiceLevel(
  carrier: RateTableCarrier,
): RateTableServiceLevel {
  switch (carrier) {
    case RateTableCarrier.USPS:
      return USPSServiceLevel.Default;
    case RateTableCarrier.FEDEX:
      return FedexServiceLevel.Default;
    default:
      assertNever(carrier);
  }
}
