import { rateTableSchema } from "@redotech/redo-model/rate-table/rate-table";
import { z } from "zod";

export const getRateTables = {
  input: z.object({
    teamId: z.string(),
  }),
  output: z.object({
    rateTables: z.array(rateTableSchema),
  }),
};
