// extracted by mini-css-extract-plugin
export var checkbox = "_y-0";
export var circle = "_y-3";
export var horizontalContainer = "_y-7";
export var sm = "_y-5";
export var switch0 = "_y-2";
export var switchDisabled = "_y-8";
export var switchEnabled = "_y-1";
export var textSwitchContainer = "_y-9";
export var textSwitchOption = "_y-a";
export var textSwitchOptionSelected = "_y-b";
export var verticalContainer = "_y-6";
export var xs = "_y-4";