import {
  DEFAULT_MMS_PRICE_IN_TENTH_CENTS,
  DEFAULT_MONTHLY_MINIMUM_IN_TENTH_CENTS,
  DEFAULT_SMS_PRICE_IN_TENTH_CENTS,
} from "@redotech/redo-model/support/billing/text-message-billing";
import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo } from "react";
import * as aiCss from "./ai.module.css";

export const supportTextMessagingForm = groupInput({
  pricePerSMSInTenthCents: input<string>(),
  pricePerMMSInTenthCents: input<string>(),
  monthlyMinimumInTenthCents: input<string>(),
  enabled: input<boolean>(),
});

export const supportTextMessagingDefault: SupportTextMessagingValue = {
  pricePerSMSInTenthCents: DEFAULT_SMS_PRICE_IN_TENTH_CENTS.toString(),
  pricePerMMSInTenthCents: DEFAULT_MMS_PRICE_IN_TENTH_CENTS.toString(),
  monthlyMinimumInTenthCents: DEFAULT_MONTHLY_MINIMUM_IN_TENTH_CENTS.toString(),
  enabled: false,
};

type SupportTextMessagingForm = InputProvider.Form<
  typeof supportTextMessagingForm
>;

type SupportTextMessagingValue = InputProvider.Value<
  typeof supportTextMessagingForm
>;

export const TextMessaging = memo(function TextMessaging({
  input,
}: {
  input: SupportTextMessagingForm;
}) {
  const inputs = input.inputs;

  return (
    <Card title="Text messaging">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span8L, aiCss.cardItems)}>
          <FormSwitch input={inputs.enabled} label="Enabled">
            Enable the feature and allow the merchant to enroll in billing
          </FormSwitch>
          <FormTextInput
            description="If the merchant has already been billed for the month, the new price will be charged the following month."
            input={inputs.monthlyMinimumInTenthCents}
            label="Base price per month"
            min={0}
            prefix="$"
            step={0.001}
            type="number"
          />
          <FormTextInput
            input={inputs.pricePerMMSInTenthCents}
            label="Price per MMS message (inbound + outbound)"
            min={0}
            prefix="$"
            step={0.001}
            type="number"
          />
          <FormTextInput
            input={inputs.pricePerSMSInTenthCents}
            label="Price per SMS message (inbound + outbound)"
            min={0}
            prefix="$"
            step={0.001}
            type="number"
          />
        </div>
      </section>
    </Card>
  );
});
