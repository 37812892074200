// web safe fonts that are available by default
export const WEB_SAFE_FONTS = [
  "Andalé Mono",
  "Arial Black",
  "Arial",
  "Baskerville",
  "Bradley Hand",
  "Brush Script MT",
  "Comic Sans MS",
  "Courier",
  "Georgia",
  "Gill Sans",
  "Helvetica",
  "Impact",
  "Lucida",
  "Luminari",
  "Monaco",
  "Palatino",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];
