import {
  DeliveryFilterType,
  DeliverySortType,
  ShippingRateTable,
} from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { Card } from "@redotech/redo-web/card";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";

import {
  groupInput,
  input,
  InputProvider,
  optionalInput,
} from "@redotech/ui/form";
import {
  arrayEqual,
  booleanEqual,
  Equal,
  objectEqual,
  optionalEqual,
  stringEqual,
} from "@redotech/util/equal";
import { memo } from "react";

export const risForm = optionalInput(
  groupInput({
    coverage: input<CoverageProductEnum>(),
    rateTables: input<ShippingRateTable[]>({
      equal: arrayEqual(
        objectEqual({
          name: stringEqual,
          description: stringEqual,
          code: stringEqual,
          type: stringEqual,
          originLocations: arrayEqual(
            objectEqual({
              country: stringEqual,
              provinces: arrayEqual(stringEqual),
            }),
          ),
          destinationLocations: arrayEqual(
            objectEqual({
              country: stringEqual,
              provinces: arrayEqual(stringEqual),
            }),
          ),
          coverageTypes: objectEqual({
            return: optionalEqual(booleanEqual),
            packageProtection: optionalEqual(booleanEqual),
          }),
          rates: arrayEqual(
            objectEqual({
              conditions: arrayEqual(objectEqual({ type: stringEqual })), //TODO figure out union type 💀
              currency: stringEqual,
              merchantPaidCoverageTypes: optionalEqual(
                objectEqual({
                  return: optionalEqual(booleanEqual),
                  packageProtection: optionalEqual(booleanEqual),
                }),
              ),
              price: stringEqual,
            }),
          ),
        }),
      ) as Equal<ShippingRateTable[]>, //I don't want to do this, but coverageType is optional and optionalEqual returns T | undefined (instead of T? | undefined)
    }),
    deliveryFilterType: optionalInput(
      input<DeliveryFilterType>(),
      () => DeliveryFilterType.ONLY_REDO,
    ),
    deliverySortType: optionalInput(
      input<DeliverySortType>(),
      () => DeliverySortType.REDO_CHEAPEST_FIRST,
    ),
  }),
  () => risDefault,
);

export enum CoverageProductEnum {
  SHIPPING = "Shipping",
  CHECKOUT = "Checkout",
}

export type RisForm = InputProvider.Form<typeof risForm>;

export type RisFormValue = InputProvider.Value<typeof risForm>;
export const risDefault = {
  coverage: CoverageProductEnum.CHECKOUT,
  rateTables: [],
  deliveryFilterType: DeliveryFilterType.ONLY_REDO,
  deliverySortType: DeliverySortType.REDO_CHEAPEST_FIRST,
};

export const CoverageProduct = memo(function CoverageProduct({
  input,
}: {
  input: RisForm;
}) {
  const ris = input.input?.inputs;
  if (!ris) {
    return null;
  }
  const { coverage } = ris;

  return (
    <Card title="Coverage product">
      <FormSelectDropdown
        description="Placement in online store"
        input={coverage}
        label="Placement"
        options={Object.values(CoverageProductEnum)}
        placeholder="Store Front"
      >
        {(s) => s}
      </FormSelectDropdown>
    </Card>
  );
});
