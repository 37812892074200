import { RouteObject } from "react-router-dom";

export const loginRoutes: RouteObject[] = [
  {
    path: "callback",
    async lazy() {
      const { CallbackPage } = await import("./callback");
      return { Component: CallbackPage };
    },
  },
  {
    index: true,
    async lazy() {
      const { LoginPage } = await import("./login");
      return { Component: LoginPage };
    },
  },
];
