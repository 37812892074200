import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { groupInput, InputProvider } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminRpcClientContext } from "../../app/redo-admin-rpc-client-provider";
import { RedoAdminClientContext } from "../../client/context";
import { getTeam, updateAccountsWebPixel, updateTeam } from "../../client/team";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import {
  ConciergeCard,
  conciergeDefault,
  conciergeForm,
} from "./customer-accounts/concierge";
import { CustomerWidgetScriptTag } from "./customer-accounts/customer-widget-script-tag";
import {
  CustomerAccountsGeneralCard,
  customerAccountsGeneralDefault,
  customerAccountsGeneralForm,
} from "./customer-accounts/general";

export const CustomerAccountsPage = memo(function CustomerAccountsPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);
  const rpcClient = useContext(RedoAdminRpcClientContext);

  const handleSave = useHandler(() => {
    loadTeam();
    doMultipassSecretLoad();
  });

  const [multipassSecretLoad, doMultipassSecretLoad] = useTriggerLoad(
    async () => {
      if (!rpcClient) {
        return;
      }

      return await rpcClient.getMultipassSecret({ teamId });
    },
  );

  useEffect(() => {
    doMultipassSecretLoad();
  }, [rpcClient]);

  const value: CustomerAccountsValue | undefined = useMemo(() => {
    if (!team || !multipassSecretLoad.value) {
      return;
    }

    return {
      general: {
        enabled: !!team.settings.customerAccounts?.enabled,
        multipassSecret: multipassSecretLoad.value.multipassSecret || "",
        loyaltyEnabled: !!team.settings.customerAccounts?.loyaltyEnabled,
        webPixelEnabled: !!team.settings.customerAccounts?.webPixelEnabled,
      },
      concierge: {
        enabled: !!team.settings.concierge?.enabled,
        placeFromThemeExtension:
          !!team.settings.concierge?.placeProductFormFromThemeExtension,
        themeExtensionCustomCss:
          team.settings.concierge?.themeExtensionProductFormCustomCss || "",
      },
    };
  }, [team, multipassSecretLoad.value]);

  return value && rpcClient ? (
    <CustomerAccountsForm id={teamId} onSave={handleSave} value={value} />
  ) : null;
});

const customerAccountsForm = groupInput({
  general: customerAccountsGeneralForm,
  concierge: conciergeForm,
});

type CustomerAccountsValue = InputProvider.Value<typeof customerAccountsForm>;

const customerAccountsDefault: CustomerAccountsValue = {
  general: customerAccountsGeneralDefault,
  concierge: conciergeDefault,
};

const CustomerAccountsForm = memo(function CustomerAccountsForm({
  id,
  onSave,
  value,
}: {
  id: string;
  onSave: (value: CustomerAccountsValue) => void;
  value: CustomerAccountsValue | undefined;
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

  const input = useInput(
    customerAccountsForm,
    value || customerAccountsDefault,
  );

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId: id, signal });
    const value = input.value;

    const previousCustomerAccountsEnabled =
      !!team.settings.customerAccounts?.enabled;

    team.settings.customerAccounts = {
      ...team.settings.customerAccounts,
      enabled: value.general.enabled,
      loyaltyEnabled: value.general.loyaltyEnabled,
    };

    team.settings.concierge = {
      ...team.settings.concierge,
      enabled: value.concierge.enabled,
      placeProductFormFromThemeExtension:
        value.concierge.placeFromThemeExtension,
      themeExtensionProductFormCustomCss:
        value.concierge.themeExtensionCustomCss || undefined,
    };

    const isEnablingCustomerAccounts =
      value.general.enabled && !previousCustomerAccountsEnabled;
    if (isEnablingCustomerAccounts) {
      // set min backfill to 10 years ago if it's not already earlier than that
      const previousOrderBackfillMin = new Date(team.orderBackfillMin);
      const now = new Date();
      const tenYearsAgo = new Date();
      tenYearsAgo.setFullYear(now.getFullYear() - 10);
      if (tenYearsAgo < previousOrderBackfillMin) {
        team.orderBackfillMin = tenYearsAgo.toString();
      }
    }

    await updateTeam(client, { teamId: id, team, signal });

    await rpcClient.setMultipassSecret({
      teamId: id,
      secret: value.general.multipassSecret,
    });

    if (input.inputs.general.inputs.webPixelEnabled.changed) {
      await updateAccountsWebPixel(client, {
        teamId: team._id,
        enabled: value.general.webPixelEnabled,
      });
    }

    onSave(value);
  });

  const { general, concierge } = input.inputs;

  return (
    <div className={teamCss.container}>
      <PageForm
        initial={value || customerAccountsDefault}
        input={input}
        save={save}
      >
        <div className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <CustomerAccountsGeneralCard input={general} />
              </div>
              <div className={gridCss.span12}>
                <ConciergeCard input={concierge} />
              </div>
            </div>
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <CustomerWidgetScriptTag />
              </div>
            </div>
          </div>
        </div>
      </PageForm>
    </div>
  );
});
