import { ShippingCoverageZodSchema } from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updateRisIntegration = {
  input: z.object({
    teamId: zExt.objectId(),
    integrationType: z.string(),
    integration: ShippingCoverageZodSchema.nullable(),
  }),
  output: ShippingCoverageZodSchema.nullable(),
};
