import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { getAuditLog, getAuditLogs } from "./schema/audit-logs/audit-logs";
import { createCommentSoldTeam } from "./schema/comment-sold/create-team";
import { getMultipassSecret } from "./schema/customer-accounts/get-multipass-secret";
import { setMultipassSecret } from "./schema/customer-accounts/set-multipass-secret";
import { createPhoneNumber } from "./schema/phone-numbers/create-phone-number";
import { getPhoneNumberByTeamIdAndUseCase } from "./schema/phone-numbers/get-phone-number-by-team-id-and-use-case";
import { updatePhoneNumber } from "./schema/phone-numbers/update-phone-number";
import { getRateTables } from "./schema/rate-tables/get-rate-tables";
import { saveRateTable } from "./schema/rate-tables/save-rate-table";
import { getRisIntegration } from "./schema/redo-in-shipping/get-ris-integration";
import { updateRisIntegration } from "./schema/redo-in-shipping/update-ris-integration";

export const rpcDefinition = {
  createCommentSoldTeam,
  getMultipassSecret,
  setMultipassSecret,
  getPhoneNumberByTeamIdAndUseCase,
  createPhoneNumber,
  updatePhoneNumber,
  getRisIntegration,
  updateRisIntegration,
  getAuditLog,
  getAuditLogs,
  getRateTables,
  saveRateTable,
};

export type AdminRpcDefinition = InferRpcDefinition<typeof rpcDefinition>;
