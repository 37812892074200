import { useSkipAnimations } from "@redotech/react-animation/transition";
import { genericMemo } from "@redotech/react-util/component";
import { Input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { ReactNode, memo, useId } from "react";
import { LabelTheme, LabeledInput } from "./labeled-input";
import * as radioCss from "./radio.module.css";
import { Text } from "./text";

export interface RadioGroupProps<T> {
  keyFn?(value: T, index: number): string;
  layout?: RadioGroupLayout;
  value: T;
  valueChange(value: T): void;
  optionLabel:
    | ((value: T) => ReactNode)
    | Record<T extends string | number | symbol ? T : never, ReactNode>;
  optionSubcontent?:
    | ((value: T) => ReactNode)
    | Record<T extends string | number | symbol ? T : never, ReactNode>;
  options: T[];
  disabled?: boolean;
  optionDescription?(value: T): string;
  bordered?: boolean;
  centerBubble?: boolean;
  alignDescription?: boolean;
  rightAligned?: boolean;
  optionClassName?: string;
}

export const RadioButton = memo(function RadioButton({
  children,
  name,
  selected,
  value,
  valueChange,
  disabled = false,
  description,
  bordered,
  centerBubble,
  alignDescription,
  // For options that should go in the group, but not be selectable
  hideRadio = false,
  rightAligned = false,
  className,
}: {
  children?: ReactNode;
  name?: string;
  selected: boolean;
  value: string;
  valueChange(value: boolean): void;
  disabled?: boolean;
  description?: string;
  bordered?: boolean;
  centerBubble?: boolean;
  hideRadio?: boolean;
  alignDescription?: boolean;
  rightAligned?: boolean;
  className?: string;
}) {
  const skipAnimations = useSkipAnimations();

  return (
    <>
      <label
        className={classNames(
          skipAnimations,
          centerBubble ? radioCss.radioButton : radioCss.radioButtonNoCenter,
          bordered
            ? selected
              ? radioCss.borderSelected
              : radioCss.border
            : null,
          rightAligned ? radioCss.rightRadio : null,
          className,
        )}
      >
        {!hideRadio && (
          <>
            <input
              checked={selected}
              className={radioCss.input}
              disabled={disabled}
              name={name}
              onChange={(event) => valueChange(event.target.checked)}
              type="radio"
              value={value}
            />
            <div className={radioCss.circle}>
              <div
                className={
                  disabled ? radioCss.circleInnerDisabled : radioCss.circleInner
                }
              />
            </div>
          </>
        )}
        {children && <div className={radioCss.label}>{children}</div>}
      </label>
      {description && (
        <Text
          as="div"
          fontSize="xs"
          pl={alignDescription ? "3xl" : undefined}
          pt="xs"
          textColor="tertiary"
        >
          {description}
        </Text>
      )}
    </>
  );
});

export type RadioGroupLayout =
  | typeof RadioGroupLayout.HORIZONTAL
  | typeof RadioGroupLayout.VERTICAL;

export namespace RadioGroupLayout {
  export const HORIZONTAL = Symbol("Horizontal");
  export const VERTICAL = Symbol("Vertical");
}

const layoutClasses = new Map<RadioGroupLayout, string>([
  [RadioGroupLayout.HORIZONTAL, radioCss.horizontal],
  [RadioGroupLayout.VERTICAL, radioCss.vertical],
]);

export const RadioGroup = genericMemo(function RadioGroup<T>({
  keyFn = (_, index) => String(index),
  layout = RadioGroupLayout.VERTICAL,
  value,
  valueChange,
  optionLabel,
  options,
  optionSubcontent,
  optionDescription,
  disabled = false,
  bordered = false,
  centerBubble = true,
  alignDescription = false,
  rightAligned = false,
  optionClassName,
}: RadioGroupProps<T>) {
  const name = useId();

  return (
    <div className={classNames(radioCss.radioGroup, layoutClasses.get(layout))}>
      {options.map((option, index): JSX.Element => {
        const key = keyFn(option, index);
        return (
          <div key={key}>
            <RadioButton
              alignDescription={alignDescription}
              bordered={bordered}
              centerBubble={centerBubble}
              className={optionClassName}
              description={optionDescription?.(option)}
              disabled={disabled}
              name={name}
              rightAligned={rightAligned}
              selected={option === value}
              value={key}
              valueChange={(value) => value && valueChange(option)}
            >
              {typeof optionLabel === "function"
                ? optionLabel(option)
                : optionLabel[option]}
            </RadioButton>
            {option === value && optionSubcontent ? (
              <div className={radioCss.subcontent}>
                {typeof optionSubcontent === "function"
                  ? optionSubcontent(option)
                  : optionSubcontent[option]}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
});

export const FormRadioGroup = genericMemo(function FormRadioGroup<T>({
  children,
  label,
  labelTheme,
  input,
  ...props
}: {
  label: ReactNode;
  labelTheme?: LabelTheme;
  children?: ReactNode | ReactNode[];
  input: Input<T>;
} & Omit<RadioGroupProps<T>, "value" | "valueChange">) {
  return (
    <LabeledInput
      description={children}
      errors={input.errors}
      label={label}
      theme={labelTheme}
    >
      <RadioGroup value={input.value} valueChange={input.setValue} {...props} />
    </LabeledInput>
  );
});
