import { TextMessagingVerificationStatus } from "@redotech/redo-model/phone/phone-number";
import { z } from "zod";

export const updatePhoneNumber = {
  input: z.object({
    id: z.string(),
    number: z.string(),
    verificationStatus: z.nativeEnum(TextMessagingVerificationStatus),
  }),
  output: z.null(),
};
