import { useHandler } from "@redotech/react-util/hook";
import { Input } from "@redotech/ui/form";
import { WEB_SAFE_FONTS } from "@redotech/web-util/fonts";
import { memo, useMemo } from "react";
import { LabeledInput } from "./labeled-input";
import { SelectDropdown } from "./select-dropdown";
import { InputTheme, TextInput } from "./text-input";

export interface FontOption {
  name: string;
  value: string;
}

export const WEB_SAFE_FONT_OPTIONS: FontOption[] = WEB_SAFE_FONTS.map(
  (font) => ({ name: font, value: font }),
);

const DEFAULT = Symbol("default");

const CUSTOM = Symbol("custom");

type Option = typeof DEFAULT | typeof CUSTOM | string;

export const FontSelect = memo(function FontSelect({
  defaultName = "Default",
  options = WEB_SAFE_FONT_OPTIONS,
  disabled,
  value,
  valueChange,
}: {
  defaultName?: string;
  options?: FontOption[];
  disabled?: boolean;
  value: string | null;
  valueChange(value: string | null): void;
}) {
  const optionMap = useMemo(
    () => new Map(options.map((option) => [option.value, option])),
    [options],
  );

  return (
    <>
      <SelectDropdown
        disabled={disabled}
        options={[[DEFAULT, CUSTOM], [...optionMap.keys()]]}
        placeholder={defaultName}
        value={value === null ? DEFAULT : optionMap.has(value) ? value : CUSTOM}
        valueChange={(value: Option) =>
          valueChange(value === DEFAULT ? null : value === CUSTOM ? "" : value)
        }
      >
        {(value: Option) =>
          value === DEFAULT ? (
            defaultName
          ) : value === CUSTOM ? (
            "Custom"
          ) : (
            <span style={{ fontFamily: value }}>
              {optionMap.get(value)!.name}
            </span>
          )
        }
      </SelectDropdown>
      {value !== null && !optionMap.has(value) && (
        <TextInput
          disabled={disabled}
          onChange={valueChange}
          placeholder="Arial, Helvetica, sans-serif..."
          theme={InputTheme.FORM}
          value={value}
        />
      )}
    </>
  );
});

export const FormFontSelect = memo(function FormFontSelect({
  input,
  defaultName,
  options,
  disabled,
  label,
  description,
}: {
  defaultName?: string;
  options?: FontOption[];
  input: Input<string | null>;
  disabled?: boolean;
  label: string;
  description?: string;
}) {
  const handleValueChange = useHandler((value: string) =>
    input.setValue(value),
  );

  return (
    <LabeledInput
      description={description}
      errors={input.allErrors}
      label={label}
    >
      <FontSelect
        defaultName={defaultName}
        disabled={disabled}
        options={options}
        value={input.value}
        valueChange={handleValueChange}
      />
    </LabeledInput>
  );
});
