// extracted by mini-css-extract-plugin
export var box = "_17-0";
export var check = "_17-1";
export var checkboxButton = "_17-6";
export var checkboxGroup = "_17-5";
export var disabled = "_17-7";
export var ghost = "_17-9";
export var input = "_17-2";
export var label = "_17-4";
export var normal = "_17-8";
export var uncheck = "_17-3";