import { useHandler } from "@redotech/react-util/hook";
import jwtDecode from "jwt-decode";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface Auth {
  expiration: Temporal.Instant;
  email: string;
  token: string;
}

export const AuthContext = createContext<Auth | undefined>(undefined);

export interface SetAuth {
  (auth: Auth | undefined): void;
}

export const SetAuthContext = createContext<SetAuth | undefined>(undefined);

const TOKEN_KEY = "redo.auth_token";

export function parseAuth(token: string): Auth | undefined {
  try {
    const decoded: unknown = jwtDecode(token);
    if (
      !decoded ||
      typeof decoded !== "object" ||
      !("exp" in decoded) ||
      typeof decoded.exp !== "number" ||
      !("eml" in decoded) ||
      typeof decoded.eml !== "string"
    ) {
      throw new TypeError("Not a valid Auth object");
      // Do not print sensitive information - only uncomment this to test locally
      // throw new TypeError(`Not a valid Auth object: ${JSON.stringify(decoded)}`);
    }
    return {
      expiration: Temporal.Instant.fromEpochSeconds(decoded.exp),
      email: decoded.eml,
      token,
    };
  } catch (error) {
    console.error("Failed to parse token", error);
    // Do not print sensitive information - only uncomment this to test locally
    // console.error(`Failed to parse invalid token ${token}`, error);
    return undefined;
  }
}

/** @returns whether the auth is expired (or expires within the next second) */
function isExpired(expiration: Temporal.Instant): boolean {
  const duration = expiration.since(Temporal.Now.instant());
  // Give ourselves 1 second leeway so it doesn't expire while trying to load the page
  return (
    Temporal.Duration.compare(
      Temporal.Duration.from({ seconds: 1 }),
      duration,
    ) > 0
  );
}

export const AuthRequired = memo(function AuthRequired({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (!auth) {
      const next = `${location.pathname}${location.search}${location.hash}`;
      navigate(`/login?next=${encodeURIComponent(next)}`, { replace: true });
    }
  }, []);

  return auth ? <>{children}</> : null;
});

export const AuthProvider = memo(function AuthProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [auth, setAuth] = useState<Auth | undefined>(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return undefined;
    }
    const parsed = parseAuth(token);
    // Delete token if invalid
    if (!parsed) {
      return undefined;
    }
    // Delete token if expired
    if (isExpired(parsed.expiration)) {
      return undefined;
    }
    return parsed;
  });

  useEffect(() => {
    // Clear the local storage if the auth is undefined
    if (!auth) {
      localStorage.removeItem(TOKEN_KEY);
      return;
    }

    // Delete the auth when it expires
    const duration = auth.expiration.since(Temporal.Now.instant());
    const timeout = setTimeout(
      () => setAuth(undefined),
      Math.min(2147483647, duration.total("milliseconds")),
    );
    return () => clearTimeout(timeout);
  }, [auth]);

  const setAuth_ = useHandler<SetAuth>((auth) => {
    if (auth) {
      localStorage.setItem(TOKEN_KEY, auth.token);
    }
    setAuth(auth);
  });

  return (
    <AuthContext.Provider value={auth}>
      <SetAuthContext.Provider value={setAuth_}>
        {children}
      </SetAuthContext.Provider>
    </AuthContext.Provider>
  );
});
