import {
  PhoneNumberProvider,
  PhoneNumberUseCase,
  TextMessagingVerificationStatus,
} from "@redotech/redo-model/phone/phone-number";
import { z } from "zod";

export const createPhoneNumber = {
  input: z.object({
    team: z.string(),
    number: z.string(),
    provider: z.nativeEnum(PhoneNumberProvider),
    useCase: z.nativeEnum(PhoneNumberUseCase),
    verificationStatus: z.nativeEnum(TextMessagingVerificationStatus),
  }),
  output: z.null(),
};
