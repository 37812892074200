// extracted by mini-css-extract-plugin
export var button = "_s-0";
export var buttonContent = "_s-9";
export var dropdownButton = "_s-7";
export var iconContainer = "_s-6";
export var lg = "_s-4";
export var md = "_s-3";
export var pending = "_s-a";
export var sm = "_s-2";
export var spinner = "_s-8";
export var xl = "_s-5";
export var xs = "_s-1";