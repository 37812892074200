import { RedoCheckboxCard } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox-card";
import Subscription from "@redotech/redo-web/arbiter-icon/currency-dollar-circle.svg";
import CoverageIcon from "@redotech/redo-web/arbiter-icon/package.svg";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { booleanEqual } from "@redotech/util/equal";
import { memo } from "react";
import { ReturnsCoverageForm } from "../returns/coverage";
export const billingTypeForm = groupInput({
  subscriptionEnabled: input<boolean>({ equal: booleanEqual }),
});

export type BillingTypeForm = InputProvider.Form<typeof billingTypeForm>;

export type BillingTypeFormValue = InputProvider.Value<typeof billingTypeForm>;
export const billingTypeDefault: BillingTypeFormValue = {
  subscriptionEnabled: false,
};

export const BillingType = memo(function BillingType({
  billingType,
  returnCoverage,
}: {
  billingType: BillingTypeForm;
  returnCoverage: ReturnsCoverageForm;
}) {
  const { subscriptionEnabled } = billingType.inputs;
  const { coverage } = returnCoverage.inputs;
  return (
    <Card className={gridCss.span12} title="Billing type">
      <Flex align="center" flexDirection="row" justify="space-evenly">
        <RedoCheckboxCard
          checked={!subscriptionEnabled.value}
          icon={<CoverageIcon />}
          inputType="radio"
          onClick={function (): void {
            if (subscriptionEnabled.value) {
              subscriptionEnabled.setValue(false);
              coverage.setValue({ credit: true, exchange: true, refund: true });
            }
          }}
          title="Coverage"
        >
          Either the customer purchases return coverage, or merchant works cost
          into shipping.
        </RedoCheckboxCard>
        <RedoCheckboxCard
          checked={subscriptionEnabled.value}
          icon={<Subscription />}
          inputType="radio"
          onClick={function (): void {
            if (!subscriptionEnabled.value) {
              subscriptionEnabled.setValue(true);
              coverage.setValue({
                credit: false,
                exchange: false,
                refund: false,
              });
            }
          }}
          title="Subscription"
        >
          Merchant pays a fee for each return, but return coverage is not
          purchased by customer.
        </RedoCheckboxCard>
      </Flex>
    </Card>
  );
});
