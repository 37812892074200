import { countries } from "@redotech/locale/countries";
import { Money } from "@redotech/money";
import { Currency, getCurrencySymbol } from "@redotech/money/currencies";
import { isOpenExchangeRatesCurrency } from "@redotech/money/openexchangerates.org/currencies";
import { IterableMap } from "@redotech/react-util/component";
import { useOnInputChange } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import {
  PaymentModel,
  pricePerOrder as calcPricePerOrder,
  paymentModelMultiplier,
} from "@redotech/redo-model/coverage";
import { Onboarding } from "@redotech/redo-model/onboarding";
import {
  Button,
  ButtonBorder,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { Checkbox, CheckboxGroup } from "@redotech/redo-web/checkbox";
import { ChipDelimiter, FormChipInput } from "@redotech/redo-web/chip-input";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { DateInput } from "@redotech/redo-web/date-picker";
import { Divider } from "@redotech/redo-web/divider";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import {
  FormMultiSelectDropdown,
  FormSelectDropdown,
} from "@redotech/redo-web/select-dropdown";
import {
  PriceBracketControls,
  Pricing,
  pricingDefault,
  pricingRuleSetDefault,
  pricingRuleSetForm,
} from "@redotech/redo-web/settings-elements/dynamic-pricing";
import {
  FinalSaleReturnsForm,
  finalSaleReturnsForm,
} from "@redotech/redo-web/settings-elements/final-sale";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Tabs } from "@redotech/redo-web/tab";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { formatDate } from "@redotech/redo-web/time";
import {
  InputProvider,
  ValidationError,
  groupInput,
  input,
  listInput,
  nonEmptyValidator,
  numberValidator,
} from "@redotech/ui/form";
import {
  arrayEqual,
  dateEqual,
  nullableEqual,
  objectEqual,
  stringEqual,
} from "@redotech/util/equal";
import * as classNames from "classnames";
import { produce } from "immer";
import { memo, useContext, useState } from "react";
import { RedoAdminClientContext } from "../../../../client/context";
import { getExchangeRate } from "../../../../client/money";
import { TeamContext } from "../../../team";
import * as teamCss from "../../../team.module.css";
import { CollectionAutocomplete } from "../collections-autocomplete";
const FACTOR_FORMAT = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const PERCENT_FORMAT = new Intl.NumberFormat(undefined, {
  style: "percent",
  maximumFractionDigits: 0,
});

export interface DateRange {
  start: Date | null;
  end: Date | null;
}
export enum ReturnsPricingEnum {
  FIXED = "FIXED",
  PER_ITEM = "PER_ITEM",
  ORDER_TOTAL = "ORDER_TOTAL",
}
export const internationalPricingForm = groupInput({
  id: input<symbol>(),
  countries: input<string[]>(),
  multiplier: input<string>({ validator: numberValidator({ min: 1 }) }),
});

export type InternationalPricingValue = InputProvider.Value<
  typeof internationalPricingForm
>;
export type InternationalPricingForm = InputProvider.Form<
  typeof internationalPricingForm
>;

export const internationalPricingDefault = (
  id: symbol,
): InternationalPricingValue => ({
  id,
  countries: [],
  multiplier: "1.5",
});

const internationalPricingListForm = listInput(
  () => internationalPricingForm,
  internationalPricingDefault,
  (i) => i.id,
);

export type InternationalPricingListForm = InputProvider.Form<
  typeof internationalPricingListForm
>;
export const returnsCoverageForm = groupInput(
  {
    coverage: groupInput({
      credit: input<boolean>(),
      exchange: input<boolean>(),
      refund: input<boolean>(),
    }),
    merchantPricePerOrder: input<string>(),
    excludeCoverageIfAnyInvalidProduct: input<boolean>(),
    paymentModel: input<PaymentModel>(),
    pricePerOrder: input<string>(),
    serviceCountries: input<string[]>({ equal: arrayEqual(stringEqual) }),
    merchantPaidEnabledDates: input<DateRange[]>({
      equal: arrayEqual(
        objectEqual({
          start: nullableEqual(dateEqual),
          end: nullableEqual(dateEqual),
        }),
      ),
      validator: (value) => {
        const errors: ValidationError[] = [];
        value.forEach((range: DateRange) => {
          if (range.start == null) {
            errors.push("Required");
          }
          if (range.start && range.end && range.start > range.end) {
            errors.push("Start must be before end");
          }
        });
        return errors;
      },
    }),
    returnPricingSelection: input<ReturnsPricingEnum>(),
    pricePerAdditonalItem: input<string>({ validator: nonEmptyValidator }),
    dynamicPriceCartValueRuleSet: listInput(
      () => pricingRuleSetForm,
      pricingRuleSetDefault,
      (_, index) => index,
    ),
    maxPrice: input<string>({ validator: nonEmptyValidator }),
    internationalPricing: internationalPricingListForm,
    finalSaleReturns: finalSaleReturnsForm,
  },
  {
    validator: (value) => {
      value.serviceCountries.length === 0;
      const countrySet = new Set(value.serviceCountries);
      let haveAllCountries = value.serviceCountries.length === 0;

      const hasDuplicateCountry = value.internationalPricing.some((pricing) => {
        const empty = pricing.countries.length === 0;
        const hasDuplicateCountry = pricing.countries.some((country) => {
          const hasDuplicate = countrySet.has(country);
          countrySet.add(country);
          return hasDuplicate;
        });

        if (empty && haveAllCountries) {
          return true;
        } else if (empty) {
          haveAllCountries = true;
        }

        return hasDuplicateCountry;
      });

      if (hasDuplicateCountry) {
        return ["Invalid country setup between domestic and international"];
      }
      return [];
    },
  },
);

export type ReturnsCoverageForm = InputProvider.Form<
  typeof returnsCoverageForm
>;

export type ReturnsCoverageValue = InputProvider.Value<
  typeof returnsCoverageForm
>;

export const returnsCoverageDefault: ReturnsCoverageValue = {
  coverage: { credit: false, exchange: false, refund: false },
  excludeCoverageIfAnyInvalidProduct: false,
  merchantPricePerOrder: "",
  merchantPaidEnabledDates: [],
  paymentModel: PaymentModel.CUSTOMER_PAID,
  pricePerOrder: "",
  serviceCountries: [],
  returnPricingSelection: ReturnsPricingEnum.FIXED,
  pricePerAdditonalItem: "",
  maxPrice: "",
  internationalPricing: [],
  dynamicPriceCartValueRuleSet: [
    {
      type: "percentage",
      countries: [],
      priceBrackets: [
        {
          value: "2",
          pricePoint: "0",
        },
      ],
    },
  ],
  finalSaleReturns: {
    enabled: false,
    allRedoPurchasesAreFinalSale: false,
    validCollections: [] as string[],
    validProductTags: [] as string[],
    ...pricingDefault,
  },
};

const InternationalReturnsPricing = memo(function InternationalReturnsPricing({
  pricing,
  countryErrors,
}: {
  pricing: InternationalPricingListForm;
  countryErrors: string[];
}) {
  function deleteRow(id: symbol) {
    return () => {
      pricing.setValue(pricing.value.filter((price) => price.id !== id));
    };
  }

  return (
    <>
      {pricing.value.length > 0 && (
        <div className={classNames(gridCss.grid, gridCss.span12)}>
          <div className={classNames(gridCss.span1)} />
          <div className={classNames(gridCss.span9)}>Countries</div>
          <div className={classNames(gridCss.span2)}>Multiplier</div>
        </div>
      )}
      <IterableMap items={pricing.inputs} keyFn={(_, i) => i}>
        {(input: InternationalPricingForm) => {
          const {
            countries: internationalCountries,
            multiplier,
            id,
          } = input.inputs;
          return (
            <div className={classNames(gridCss.grid, gridCss.span12)}>
              <div className={classNames(gridCss.span1)}>
                <IconButton onClick={deleteRow(id.value)}>
                  <div className={classNames()}>
                    <TrashIcon />
                  </div>
                </IconButton>
              </div>
              <div className={classNames(gridCss.span8)}>
                <FormMultiSelectDropdown
                  display={(value) => {
                    if (value.length === 0) {
                      return "Everywhere else";
                    }
                    if (value.length === countries.length) {
                      return "Everywhere else";
                    }
                    const names = value.map(
                      (code) =>
                        countries.find((country) => country.code === code)
                          ?.name,
                    );
                    return names.join(", ");
                  }}
                  input={internationalCountries}
                  label=""
                  options={countries.map((country: any) => country.code)}
                >
                  {(option) =>
                    countries.find((country) => country.code === option)?.name
                  }
                </FormMultiSelectDropdown>
              </div>
              <div className={classNames(gridCss.span3)}>
                <FormTextInput
                  description=""
                  input={multiplier}
                  label=""
                  prefix="x"
                  type="number"
                />
              </div>
            </div>
          );
        }}
      </IterableMap>

      <div className={classNames(gridCss.span12, teamCss.centered)}>
        <Button
          border={ButtonBorder.LIGHT}
          onClick={() =>
            pricing.setValue([
              ...pricing.value,
              {
                id: Symbol(),
                countries: [],
                multiplier: "1.5",
              },
            ])
          }
          theme={ButtonTheme.OUTLINED}
        >
          Add International Countries
        </Button>
      </div>

      <div className={classNames(gridCss.span12, teamCss.centered)}>
        <span className={classNames(teamCss.errorText)}>
          {countryErrors?.[0]}
        </span>
      </div>
    </>
  );
});

enum LocationSet {
  DOMESTIC = "Domestic",
  INTERNATIONAL = "International",
}

const LocationSetLables = {
  [LocationSet.DOMESTIC]: <>Domestic</>,
  [LocationSet.INTERNATIONAL]: <>International</>,
};

const DynamicReturnsPricingCartValue = memo(
  function DynamicReturnsPricingCartValue({
    input,
  }: {
    input: ReturnsCoverageForm;
  }) {
    const {
      merchantPricePerOrder,
      dynamicPriceCartValueRuleSet,
      maxPrice,
      pricePerOrder,
    } = input.inputs;

    const team = useContext(TeamContext);
    return (
      <div className={classNames(gridCss.span12, gridCss.grid)} dir="column">
        <div className={gridCss.span6L}>
          <FormTextInput
            description="base price of redo"
            input={pricePerOrder}
            label="Min Price"
            prefix={getCurrencySymbol(team?._shopify.currency || Currency.USD)}
            suffix={
              team?._shopify.currency === "USD" ? "" : team?._shopify.currency
            }
          />
        </div>
        <div className={gridCss.span6L}>
          <FormTextInput
            description={`(backend < min) For rev share or (backend > min) for subsidized Coverage. For example if backend price = $.50 and base coverage = $1.00 we'll do a 50% rev share. `}
            input={merchantPricePerOrder}
            label="Backend price"
            placeholder={pricePerOrder.value}
            prefix={getCurrencySymbol(team?._shopify.currency || Currency.USD)}
            suffix={
              team?._shopify.currency === "USD" ? "" : team?._shopify.currency
            }
          />
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormTextInput
            description=""
            input={maxPrice}
            label="Max price"
            min={+pricePerOrder.value}
            prefix={getCurrencySymbol(team?._shopify.currency || Currency.USD)}
            step={0.01}
            suffix={
              team?._shopify.currency === "USD" ? "" : team?._shopify.currency
            }
            type="number"
          />
        </div>

        <div className={classNames(gridCss.span12)}>
          <PriceBracketControls
            adminApp
            canEditSettings
            index={0}
            minMaxErrors={input.errors}
            pricingRuleSet={dynamicPriceCartValueRuleSet.inputs[0]}
          />
        </div>
      </div>
    );
  },
);

export const ReturnsCoverageCard = memo(function ReturnsCoverageCard({
  input,
  onboarding,
}: {
  input: ReturnsCoverageForm;
  onboarding: Onboarding | undefined;
}) {
  let {
    coverage,
    excludeCoverageIfAnyInvalidProduct,
    merchantPaidEnabledDates,
    merchantPricePerOrder,
    paymentModel,
    pricePerOrder,
    serviceCountries,
    returnPricingSelection,
    pricePerAdditonalItem,
    maxPrice,
    internationalPricing,
  } = input.inputs;
  const { credit, exchange, refund } = coverage.inputs;
  const [property, setProperty] = useState(LocationSet.DOMESTIC);

  const team = useContext(TeamContext);
  const client = useContext(RedoAdminClientContext);

  const exchangeRateLoad = useLoad(async () => {
    if (
      !team?._shopify.currency ||
      team._shopify.currency === Currency.USD ||
      !client ||
      !isOpenExchangeRatesCurrency(team._shopify.currency)
    ) {
      return null;
    }
    const response = await getExchangeRate(
      client,
      Currency.USD,
      team._shopify.currency,
    );
    return response.USD[team._shopify.currency];
  }, [team]);

  const getRatio = (
    params: {
      exchange?: boolean;
      refund?: boolean;
      credit?: boolean;
      paymentModel?: PaymentModel;
    } = {},
  ) => {
    return calcPricePerOrder(
      {
        exchange:
          params.exchange !== undefined
            ? params.exchange
            : input.value.coverage.exchange,
        refund:
          params.refund !== undefined
            ? params.refund
            : input.value.coverage.refund,
        storeCredit:
          params.credit !== undefined
            ? params.credit
            : input.value.coverage.credit,
      },
      params.paymentModel !== undefined
        ? params.paymentModel
        : input.value.paymentModel,
      new Money(1, Currency.USD),
    ).amount;
  };

  const ratio = getRatio();
  const base = +pricePerOrder.value / ratio;

  const adjustPrice = (params: {
    exchange?: boolean;
    refund?: boolean;
    credit?: boolean;
    paymentModel?: PaymentModel;
  }) => {
    const ratio = getRatio(params);
    pricePerOrder.setValue((base * ratio).toFixed(2));
  };

  paymentModel = useOnInputChange(paymentModel, (value) => {
    adjustPrice({ paymentModel: value });
    return value;
  });

  const paymentModelMult = paymentModelMultiplier(paymentModel.value);

  const calcBase =
    onboarding?.orders &&
    ((2 * onboarding.orders.realRefundPercentage) / 100) *
      +onboarding.labelPrice;

  function MerchantPaidEnabledDates() {
    const handleChange = useHandler(
      (date: Date | null, index: number, placement: string) => {
        const { merchantPaidEnabledDates } = input.inputs;
        if (placement === "start") {
          merchantPaidEnabledDates.setValue(
            produce(merchantPaidEnabledDates.value, (dates: any) => {
              dates[index].start = date;
            }),
          );
        } else {
          merchantPaidEnabledDates.setValue(
            produce(merchantPaidEnabledDates.value, (dates: any) => {
              dates[index].end = date;
            }),
          );
        }
      },
    );

    return (
      <>
        {merchantPaidEnabledDates.value.map(
          (range: { start: Date | null; end: Date | null }, index: number) => (
            <div className={teamCss.merchantPaidDateRange} key={index}>
              <div className={teamCss.date}>
                <LabeledInput label="Start">
                  <DateInput
                    value={range.start}
                    valueChange={(val) => handleChange(val, index, "start")}
                  />
                </LabeledInput>
              </div>
              <div className={teamCss.date}>
                <LabeledInput label="End (leave blank for active)">
                  <DateInput
                    value={range.end}
                    valueChange={(val) => handleChange(val, index, "end")}
                  />
                </LabeledInput>
              </div>
            </div>
          ),
        )}
      </>
    );
  }

  return (
    <Card title="Returns coverage">
      <section className={gridCss.grid}>
        <div className={gridCss.span6}>
          <LabeledInput label="Covered return methods">
            <CheckboxGroup>
              <Checkbox onChange={credit.setValue} value={credit.value}>
                Store credit
              </Checkbox>
              <Checkbox onChange={exchange.setValue} value={exchange.value}>
                Exchange
              </Checkbox>
              <Checkbox onChange={refund.setValue} value={refund.value}>
                Refund
              </Checkbox>
            </CheckboxGroup>
          </LabeledInput>
        </div>
        <div className={gridCss.span6}>
          <FormSwitch
            input={excludeCoverageIfAnyInvalidProduct}
            label="Exclude coverage on invalid products"
          >
            Do not offer coverage if any product in the cart is invalid
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12)}>
          <LabeledInput
            description="All dates selected are start of the day in UTC"
            label="Merchant paid coverage"
          >
            {MerchantPaidEnabledDates()}
            <div
              className={teamCss.add}
              onClick={() =>
                merchantPaidEnabledDates.setValue([
                  ...merchantPaidEnabledDates.value,
                  { start: null, end: null },
                ])
              }
            >
              Add date range
              <PlusIcon />
            </div>
          </LabeledInput>
        </div>

        <div className={classNames(gridCss.span12)}>
          <Tabs<LocationSet>
            keyFn={(option) => option}
            options={[LocationSet.DOMESTIC, LocationSet.INTERNATIONAL]}
            tab={(option) => LocationSetLables[option]}
            value={property}
            valueChange={setProperty}
          >
            {property === LocationSet.DOMESTIC ? (
              <div className={classNames(gridCss.grid, gridCss.span12)}>
                <div className={gridCss.span12}>
                  <FormSelectDropdown
                    input={returnPricingSelection}
                    label="Pricing Strategy"
                    options={[...Object.values(ReturnsPricingEnum)]}
                  >
                    {(option) => {
                      switch (option) {
                        case ReturnsPricingEnum.FIXED:
                          return "Single Fixed Price";
                        case ReturnsPricingEnum.ORDER_TOTAL:
                          return "Dynamic: cart total";
                        case ReturnsPricingEnum.PER_ITEM:
                          return "Dynamic: number of items";
                      }
                    }}
                  </FormSelectDropdown>
                </div>

                {returnPricingSelection.value ===
                  ReturnsPricingEnum.PER_ITEM && (
                  <div className={classNames(gridCss.span12, gridCss.grid)}>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description="Price of redo with one item in cart"
                        input={pricePerOrder}
                        label="Base price"
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description={`(backend < base) For rev share or (backend > base) for subsidized Coverage. For example if backend price = $.50 and base coverage = $1.00 we'll do a 50% rev share. `}
                        input={merchantPricePerOrder}
                        label="Backend price"
                        placeholder={pricePerOrder.value}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description=""
                        input={pricePerAdditonalItem}
                        label="Price per additional item"
                        min={0.01}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        step={0.01}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                        type="number"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description=""
                        input={maxPrice}
                        label="Max price"
                        min={+pricePerOrder.value}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        step={0.01}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                        type="number"
                      />
                    </div>
                  </div>
                )}
                {returnPricingSelection.value === ReturnsPricingEnum.FIXED && (
                  <div className={classNames(gridCss.span12, gridCss.grid)}>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description={
                          <>
                            {CURRENCY_FORMAT().format(base)} ×{" "}
                            {FACTOR_FORMAT.format(paymentModelMult)}
                          </>
                        }
                        input={pricePerOrder}
                        label="Coverage price"
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description="If the merchant subsidizes the price, the full price of coverage. (E.g. customer pays $1.50, merchant contributes $0.50, full price is $1.98)"
                        input={merchantPricePerOrder}
                        label="Backend price"
                        placeholder={pricePerOrder.value}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                  </div>
                )}
                {returnPricingSelection.value ===
                  ReturnsPricingEnum.ORDER_TOTAL && (
                  <DynamicReturnsPricingCartValue input={input} />
                )}
                <div className={classNames(gridCss.span12)}>
                  <FormMultiSelectDropdown
                    display={(value) => {
                      if (value.length === 0) {
                        return "All";
                      }
                      if (value.length === countries.length) {
                        return "All";
                      }
                      const names = value.map(
                        (code) =>
                          countries.find((country) => country.code === code)
                            ?.name,
                      );
                      return names.join(", ");
                    }}
                    input={serviceCountries}
                    label="Domestic countries"
                    options={countries.map((country: any) => country.code)}
                  >
                    {(option) =>
                      countries.find((country) => country.code === option)?.name
                    }
                  </FormMultiSelectDropdown>
                </div>
                <div className={classNames(gridCss.span12, teamCss.centered)}>
                  <span className={classNames(teamCss.errorText)}>
                    {input.allErrors?.[0]}
                  </span>
                </div>
              </div>
            ) : (
              <div className={classNames(gridCss.span12, teamCss.flexOne)}>
                <InternationalReturnsPricing
                  countryErrors={input.allErrors}
                  pricing={internationalPricing}
                />
              </div>
            )}
          </Tabs>
        </div>

        <div className={gridCss.span12}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>
            {onboarding?.finished
              ? "Onboarding order statistics"
              : "Order statistics not yet calculated"}
          </strong>
        </div>
        <div className={gridCss.span12}>
          <dl className={teamCss.table}>
            <dt className={teamCss.tableHeader}>Start</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                formatDate(Temporal.Instant.from(onboarding.orders.fromDate))}
            </dd>
            <dt className={teamCss.tableHeader}>End</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                formatDate(Temporal.Instant.from(onboarding.orders.toDate))}
            </dd>
            <dt className={teamCss.tableHeader}>Orders</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                new Intl.NumberFormat().format(onboarding.orders.count)}
            </dd>
            <dt className={teamCss.tableHeader}>Refunded orders</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                new Intl.NumberFormat().format(onboarding.orders.countRefunded)}
            </dd>
            <dt className={teamCss.tableHeader}>Refund rate</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                PERCENT_FORMAT.format(onboarding.orders.refundPercentage / 100)}
            </dd>
            <dt className={teamCss.tableHeader}>Estimated return rate</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                PERCENT_FORMAT.format(
                  onboarding.orders.realRefundPercentage / 100,
                )}
            </dd>
            <dt className={teamCss.tableHeader}>Price per label</dt>
            <dd className={teamCss.tableCell}>
              {onboarding && CURRENCY_FORMAT().format(+onboarding.labelPrice)}
            </dd>
            <dt className={teamCss.tableHeader}>Calculated base</dt>
            <dd className={teamCss.tableCell}>
              {calcBase !== undefined && CURRENCY_FORMAT().format(calcBase)}
            </dd>
            <dt className={teamCss.tableHeader}>Calculated price</dt>
            <dd className={teamCss.tableCell}>
              {calcBase !== undefined &&
                CURRENCY_FORMAT().format(ratio * calcBase)}{" "}
            </dd>
            {team?._shopify.currency &&
              team._shopify.currency !== Currency.USD &&
              exchangeRateLoad.value && (
                <>
                  <dt className={teamCss.tableHeader}>
                    Calculated price in {team?._shopify.currency}{" "}
                  </dt>
                  <dd className={teamCss.tableCell}>
                    {calcBase !== undefined &&
                      CURRENCY_FORMAT(team?._shopify.currency).format(
                        ratio * calcBase * exchangeRateLoad.value,
                      )}{" "}
                  </dd>
                </>
              )}
          </dl>
        </div>
      </section>
    </Card>
  );
});

export const FinalSaleReturnsCard = memo(function FinalSaleReturnsCard({
  input,
}: {
  input: FinalSaleReturnsForm;
}) {
  const { enabled, validCollections, validProductTags } = input.inputs;

  const team = useContext(TeamContext);

  return (
    <Card title="Final sale returns by Redo">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <FormSwitch input={enabled} label="Coverage Enabled">
            Enable customers to buy coverage
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <FormChipInput
            delimiter={ChipDelimiter.NEWLINE}
            description="Coverage will be added for these Shopify order tags (e.g. wholesale)."
            input={validProductTags}
            label="Included order tags"
            trimWhitespace
          />
        </div>
        <div className={gridCss.span12}>
          <LabeledInput
            description="Coverage will be offered for these Shopify collections. The first 100 collections are shown by default. To access more, search by title."
            label="Included collections"
          >
            <CollectionAutocomplete
              keyFn={(collection) => collection.handle}
              setValue={validCollections.setValue}
              value={validCollections.value}
            />
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12)}>
          <Divider />
        </div>
        <div className={classNames(gridCss.span12)}>
          <strong>Pricing</strong>
        </div>
        <div className={gridCss.span12}>
          <Pricing
            adminApp
            canEditSettings
            currencyDisplay={getCurrencySymbol(
              team?._shopify.currency || Currency.USD,
            )}
            input={input}
          />
        </div>
      </section>
    </Card>
  );
});
