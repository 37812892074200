import { Team } from "@redotech/redo-model/team";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import * as round from "lodash/round";
import {
  minMaxValidator,
  pricingFormValues,
  pricingRulesConvertTeamToValue,
  pricingRulesConvertValueToTeam,
} from "./dynamic-pricing";

export const finalSaleReturnsForm = groupInput(
  {
    enabled: input<boolean>(),
    validCollections: input<string[]>({ equal: arrayEqual(stringEqual) }),
    validProductTags: input<string[]>({ equal: arrayEqual(stringEqual) }),
    allRedoPurchasesAreFinalSale: input<boolean>(),
    ...pricingFormValues,
  },
  { validator: minMaxValidator },
);

export type FinalSaleReturnsForm = InputProvider.Form<
  typeof finalSaleReturnsForm
>;

export type FinalSaleReturnsValue = InputProvider.Value<
  typeof finalSaleReturnsForm
>;

export function finalSaleTeamToFormValue(team: Team): FinalSaleReturnsValue {
  return {
    // General
    enabled: team.settings.returns?.finalSaleReturns?.enabled || false,
    validCollections:
      team.settings.returns?.finalSaleReturns?.validCollections || [],
    validProductTags:
      team.settings.returns?.finalSaleReturns?.validProductTags || [],
    minPrice:
      team.settings.returns?.finalSaleReturns?.minPrice?.toString() || "1",
    maxPrice:
      team.settings.returns?.finalSaleReturns?.maxPrice?.toString() || "15",
    percentage: team.settings.returns?.finalSaleReturns?.percentage
      ? String(
          round(team.settings.returns.finalSaleReturns.percentage * 100.0, 3),
        )
      : "2",
    pricingRuleSet: pricingRulesConvertTeamToValue(
      team.settings.returns?.finalSaleReturns?.pricingRuleSet,
      team.settings.countries,
      team.settings.returns?.finalSaleReturns?.percentage,
    ),
    internationalPricingEnabled: team.settings.returns?.finalSaleReturns
      ?.pricingRuleSet?.length
      ? team.settings.returns?.finalSaleReturns?.pricingRuleSet?.length > 1
      : false,
    allRedoPurchasesAreFinalSale:
      team.settings.returns?.finalSaleReturns?.allRedoPurchasesAreFinalSale ??
      false,
  };
}

export function finalSaleFormValueToTeam(
  finalSaleReturns: FinalSaleReturnsValue,
) {
  return {
    enabled: finalSaleReturns.enabled,
    validCollections: finalSaleReturns.validCollections,
    validProductTags: finalSaleReturns.validProductTags,
    minPrice: Number(finalSaleReturns.minPrice),
    maxPrice: Number(finalSaleReturns.maxPrice),
    percentage: round(+finalSaleReturns.percentage, 3) / 100.0,
    allRedoPurchasesAreFinalSale: finalSaleReturns.allRedoPurchasesAreFinalSale,
    pricingRuleSet: pricingRulesConvertValueToTeam(
      finalSaleReturns.pricingRuleSet,
    ),
  };
}
