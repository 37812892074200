import {
  JsonFormat,
  jsonSerializer,
  objectJsonFormat,
  stringJsonFormat,
} from "@redotech/json/format";
import { useHandler } from "@redotech/react-util/hook";
import { ReactNode, createContext, memo, useContext, useState } from "react";
import { AuthContext } from "./auth";

export interface User {
  name: { first: string; last: string };
}

export const userJsonFormat: JsonFormat<User> = objectJsonFormat(
  {
    name: objectJsonFormat<User["name"]>(
      { first: stringJsonFormat, last: stringJsonFormat },
      {},
    ),
  },
  {},
);

export const UserContext = createContext<User | undefined>(undefined);

export interface SetUser {
  (user: User | undefined): void;
}

export const SetUserContext = createContext<SetUser | undefined>(undefined);

const USER_KEY = "redo.user";

export const UserProvider = memo(function UserProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const auth = useContext(AuthContext);
  const [user, setUser] = useState<User | undefined>(() => {
    const json = localStorage.getItem(USER_KEY);
    return json ? jsonSerializer(userJsonFormat).read(json) : undefined;
  });
  const setUser_ = useHandler<SetUser>((user) => {
    if (user === undefined) {
      localStorage.removeItem(USER_KEY);
    } else {
      localStorage.setItem(
        USER_KEY,
        jsonSerializer(userJsonFormat).write(user),
      );
    }
    setUser(user);
  });

  return (
    <UserContext.Provider value={auth && user}>
      <SetUserContext.Provider value={setUser_}>
        {children}
      </SetUserContext.Provider>
    </UserContext.Provider>
  );
});
