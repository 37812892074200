import { CoverageMonitor as CoverageMonitorType } from "@redotech/redo-model/coverage-monitor";
import { RedoAdminClient } from ".";

/**
 * GET /teams/:teamId/coverageMonitoring
 */
export async function getCoverageMonitoring(
  client: RedoAdminClient,
  { teamId, signal }: { teamId: string; signal?: AbortSignal },
): Promise<CoverageMonitorType> {
  const response = await client.client.get(
    `teams/${encodeURIComponent(teamId)}/coverageMonitoring`,
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * GET /teams/:teamId/coverageMonitoring/reset
 */
export async function resetCoverageMonitoring(
  client: RedoAdminClient,
  { teamId }: { teamId: string },
): Promise<CoverageMonitorType> {
  const response = await client.client.get(
    `teams/${encodeURIComponent(teamId)}/coverageMonitoring/reset`,
    {
      headers: client.authorization(),
    },
  );
  return response.data;
}
