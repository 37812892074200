import {
  PhoneNumberSchema,
  PhoneNumberUseCase,
} from "@redotech/redo-model/phone/phone-number";
import { z } from "zod";

export const getPhoneNumberByTeamIdAndUseCase = {
  input: z.object({
    teamId: z.string(),
    useCase: z.nativeEnum(PhoneNumberUseCase),
  }),
  output: PhoneNumberSchema.nullable(),
};
