import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { InputSize } from "@redotech/redo-web/input";
import {
  LabelOrientation,
  LabelPosition,
  LabelTheme,
} from "@redotech/redo-web/labeled-input";
import { FormSwitch } from "@redotech/redo-web/switch";
import {
  FormTextInput,
  InputBoxHeight,
  InputLines,
} from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import classNames from "classnames";
import { memo } from "react";

export const conciergeForm = groupInput({
  enabled: input<boolean>(),
  placeFromThemeExtension: input<boolean>(),
  themeExtensionCustomCss: input<string>(),
});

export type ConciergeForm = InputProvider.Form<typeof conciergeForm>;

export type ConciergeValue = InputProvider.Value<typeof conciergeForm>;

export const conciergeDefault: ConciergeValue = {
  enabled: false,
  placeFromThemeExtension: false,
  themeExtensionCustomCss: "",
};

export const ConciergeCard = memo(function ConciergeCard({
  input,
}: {
  input: ConciergeForm;
}) {
  const { enabled, placeFromThemeExtension, themeExtensionCustomCss } =
    input.inputs;

  return (
    <Card title="Concierge">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <div className={gridCss.grid}>
            <div className={classNames(gridCss.span6L, gridCss.span12)}>
              <FormSwitch
                input={enabled}
                label="Concierge enabled"
                orientation={LabelOrientation.VERTICAL}
                position={LabelPosition.RIGHT}
              >
                Enable Concierge service
              </FormSwitch>
            </div>
          </div>
        </div>
        <div className={gridCss.span12}>
          <div className={gridCss.grid}>
            <div className={classNames(gridCss.span6L, gridCss.span12)}>
              <FormSwitch
                input={placeFromThemeExtension}
                label="Place product form from theme extension"
                orientation={LabelOrientation.VERTICAL}
                position={LabelPosition.RIGHT}
              >
                This will place the Concierge entry point on the PDP via the
                theme extension. For use in cases where the merchant's theme
                does not allow adding it via the App Block.
              </FormSwitch>
            </div>
          </div>
        </div>
        {placeFromThemeExtension.value && (
          <div className={gridCss.span12}>
            <div className={gridCss.grid}>
              <div className={classNames(gridCss.span6L, gridCss.span12)}>
                <FormTextInput
                  description="Add custom CSS rules to apply to the Concierge Product Form. Example: padding-top: 16px; padding-bottom: 8px;"
                  input={themeExtensionCustomCss}
                  label="Custom CSS"
                  labelTheme={LabelTheme.THIN_BOLD}
                  lines={InputLines.MULTI}
                  size={InputSize.SMALL}
                  textAreaHeight={InputBoxHeight.MEDIUM}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </Card>
  );
});
