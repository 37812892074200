import { Card } from "@redotech/redo-web/card";
import { DateInput } from "@redotech/redo-web/date-picker";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { memo } from "react";

const oneClickUpsellBillingForm = groupInput({
  billingEnabled: input<boolean>(),
  freeUntilDate: input<string | undefined>(),
  freeUpsellsShownPerMonth: input<string>(),
  pricePerUpsellShownInCents: input<string>(),
});

export const oneClickUpsellTeamSettingsForm = groupInput({
  enabled: input<boolean>(),
  billing: oneClickUpsellBillingForm,
});

export type OneClickUpsellTeamSettingsForm = InputProvider.Form<
  typeof oneClickUpsellTeamSettingsForm
>;

export type OneClickUpsellTeamSettingsValue = InputProvider.Value<
  typeof oneClickUpsellTeamSettingsForm
>;

export const oneClickUpsellTeamSettingsDefault: OneClickUpsellTeamSettingsValue =
  {
    enabled: false,
    billing: {
      billingEnabled: false,
      freeUntilDate: undefined,
      freeUpsellsShownPerMonth: "0",
      pricePerUpsellShownInCents: "0",
    },
  };

export const OneClickUpsellTeamSettingsCard = memo(
  function OneClickUpsellTeamSettingsCard({
    input,
  }: {
    input: OneClickUpsellTeamSettingsForm;
  }) {
    const inputs = input.inputs;

    const billingInputs = inputs.billing.inputs;

    const freeUntil = billingInputs.freeUntilDate.value
      ? new Date(billingInputs.freeUntilDate.value)
      : null;

    const setFreeUntil = (date: Date | null) => {
      billingInputs.freeUntilDate.setValue(date?.toISOString());
    };

    return (
      <Card title="One-click upsell">
        <section className={gridCss.grid}>
          <div className={gridCss.span6L}>
            <FormSwitch input={inputs.enabled} label="Enabled">
              Enable One-click upsell
            </FormSwitch>
          </div>
          <div className={gridCss.span6L}>
            <FormSwitch
              input={billingInputs.billingEnabled}
              label="Billing enabled"
            >
              Attempt billing for each upsell attempted
            </FormSwitch>
          </div>
          <Flex className={gridCss.span8L} dir="column" gap="xl">
            <LabeledInput
              description="Even if billing is enabled, billing will not be attempted until this date"
              label="Free until date"
            >
              <DateInput value={freeUntil} valueChange={setFreeUntil} />
            </LabeledInput>
            <FormTextInput
              description="Upsell attempts we wont charge for per month. The month always starts on the 1st."
              input={billingInputs.freeUpsellsShownPerMonth}
              label="Free upsell attempts per month"
              min={0}
              step={1}
              type="number"
            />
            <FormTextInput
              description="Amount we will charge for each upsell attempt"
              input={billingInputs.pricePerUpsellShownInCents}
              label="Price per upsell"
              min={0}
              prefix="$"
              step={0.01}
              type="number"
            />
          </Flex>
        </section>
      </Card>
    );
  },
);
