import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PackageProtectionForm } from "@redotech/redo-web/settings-elements/package-protection-elements";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import * as classNames from "classnames";
import { memo } from "react";

export const PackageProtectionGeneralCard = memo(
  function PackageProtectionGeneralCard({
    input,
  }: {
    input: PackageProtectionForm;
  }) {
    const {
      enabled,
      customerClaimsEnabled,
      packageProtectionPlusEnabled,
      packageProtectionPlusPercentage,
    } = input.inputs;

    return (
      <Card title="Package Protection">
        <section className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormSwitch input={enabled} label="Enabled">
              Enable Package Protection
            </FormSwitch>
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormSwitch
              input={packageProtectionPlusEnabled}
              label="Package Protection Plus"
            >
              Enable Package Protection plus
            </FormSwitch>
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormTextInput
              description="Percentage of package protection coverage that Redo keeps. Don't touch this unless you have talked to Dan."
              input={packageProtectionPlusPercentage}
              label="PP Plus percentage"
              max={100}
              min={0.01}
              step={0.01}
              suffix="%"
            />
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormSwitch
              input={customerClaimsEnabled}
              label="Customer claim processing"
            >
              Enable customers to create claims in Customer Portal
            </FormSwitch>
          </div>
        </section>
      </Card>
    );
  },
);
