import { PricingRuleSet, Team } from "@redotech/redo-model/team";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { nativeRandom, randomHexString } from "@redotech/util/random";
import * as round from "lodash/round";
import { memo } from "react";
import {
  minMaxValidator,
  Pricing,
  pricingDefault,
  pricingFormValues,
  pricingRulesConvertValueToTeam,
} from "./dynamic-pricing";

export const packageProtectionForm = groupInput(
  {
    // General
    enabled: input<boolean>(),
    customerClaimsEnabled: input<boolean>(),
    packageProtectionPlusEnabled: input<boolean>(),
    packageProtectionPlusPercentage: input<string>(),
    // Coverage
    coverage: input<boolean>(),
    // Pricing
    ...pricingFormValues,
  },
  { validator: minMaxValidator },
);

export type PackageProtectionForm = InputProvider.Form<
  typeof packageProtectionForm
>;

export type PackageProtectionValue = InputProvider.Value<
  typeof packageProtectionForm
>;

export const packageProtectionDefault: PackageProtectionValue = {
  // General
  enabled: false,
  customerClaimsEnabled: false,
  packageProtectionPlusEnabled: false,
  packageProtectionPlusPercentage: "0",
  // Coverage
  coverage: false,
  // Pricing
  ...pricingDefault,
};

export const PackageProtectionPricing = memo(function PackageProtectionPricing({
  input,
  canEditSettings,
  adminApp = false,
  currencyDisplay,
}: {
  input: PackageProtectionForm;
  canEditSettings: boolean;
  adminApp?: boolean;
  currencyDisplay?: string;
}) {
  return (
    <Pricing
      adminApp={adminApp}
      canEditSettings={canEditSettings}
      currencyDisplay={currencyDisplay}
      input={input}
    />
  );
});

export function pricingRulesConvertTeamToValue(team: Team) {
  let pricingRuleSetReturn: any = [];
  if (team.settings.packageProtection?.pricingRuleSet?.length) {
    pricingRuleSetReturn = team.settings.packageProtection?.pricingRuleSet.map(
      (x: PricingRuleSet) => {
        return {
          type: x.type,
          countries: x.countries,
          key: randomHexString(nativeRandom, 12),
          priceBrackets: x.priceBrackets.map((pb) => {
            let value = pb.value;
            if (x.type === "percentage") {
              value = round(pb.value * 100, 3);
            }
            return {
              key: randomHexString(nativeRandom, 12),
              value: String(value),
              pricePoint: String(pb.pricePoint),
            };
          }),
        };
      },
    );
  } else if (!pricingRuleSetReturn.length) {
    pricingRuleSetReturn.push({
      key: randomHexString(nativeRandom, 12),
      type: "percentage",
      countries: team.settings.countriesPackageProtection,
      priceBrackets: [
        {
          key: randomHexString(nativeRandom, 12),
          value: team.settings.packageProtection?.percentage
            ? String(
                round(team.settings.packageProtection?.percentage * 100, 3),
              )
            : "2",
          pricePoint: "0",
        },
      ],
    });
  }

  return pricingRuleSetReturn;
}

export function packageProtectionTeamToFormValue(
  team: Team,
): PackageProtectionValue {
  return {
    // General
    enabled: team.settings.packageProtection?.enabled || false,
    customerClaimsEnabled:
      team.settings.packageProtection?.customerClaimsEnabled || false,
    packageProtectionPlusEnabled:
      team.settings.packageProtection?.packageProtectionPlusEnabled || false,
    packageProtectionPlusPercentage: team.settings.packageProtection
      ?.packageProtectionPlusPercentage
      ? String(
          round(
            team.settings.packageProtection.packageProtectionPlusPercentage *
              100.0,
            3,
          ),
        )
      : "0",
    // Coverage
    coverage: team.settings.packageProtection?.coverage || false,
    minPrice: team.settings.packageProtection?.minPrice?.toString() || "1",
    maxPrice: team.settings.packageProtection?.maxPrice?.toString() || "15",
    percentage: team.settings.packageProtection?.percentage
      ? String(round(team.settings.packageProtection.percentage * 100.0, 3))
      : "2",
    pricingRuleSet: pricingRulesConvertTeamToValue(team),
    internationalPricingEnabled: team.settings.packageProtection?.pricingRuleSet
      ?.length
      ? team.settings.packageProtection?.pricingRuleSet?.length > 1
      : false,
  };
}

export function packageProtectionFormValueToTeam(
  packageProtection: PackageProtectionValue,
  splitProducts: boolean,
) {
  return {
    enabled: packageProtection.enabled,
    coverage: packageProtection.coverage,
    customerClaimsEnabled: packageProtection.customerClaimsEnabled,
    packageProtectionPlusEnabled:
      packageProtection.packageProtectionPlusEnabled,
    packageProtectionPlusPercentage:
      round(+packageProtection.packageProtectionPlusPercentage, 3) / 100.0,
    minPrice: Number(packageProtection.minPrice),
    maxPrice: Number(packageProtection.maxPrice),
    percentage: round(+packageProtection.percentage, 3) / 100.0,
    splitProducts: splitProducts,
    pricingRuleSet: pricingRulesConvertValueToTeam(
      packageProtection.pricingRuleSet,
    ),
  };
}
