import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { memo } from "react";

export const reviewsGeneralForm = groupInput({
  enabled: input<boolean>(),
});

export type ReviewsGeneralForm = InputProvider.Form<typeof reviewsGeneralForm>;

export type ReviewsGeneralValue = InputProvider.Value<
  typeof reviewsGeneralForm
>;

export const reviewsGeneralDefault: ReviewsGeneralValue = {
  enabled: false,
};

export const ReviewsGeneralCard = memo(function ReviewsGeneralCard({
  input,
}: {
  input: ReviewsGeneralForm;
}) {
  const { enabled } = input.inputs;
  return (
    <Card title="Reviews">
      <section className={gridCss.grid}>
        <div className={gridCss.span6L}>
          <FormSwitch input={enabled} label="Enabled">
            Enable Reviews product
          </FormSwitch>
        </div>
      </section>
    </Card>
  );
});
