import type { RedoAdminRpcClient } from "@redotech/admin-sdk/rpc/client";
import { createAdminRpcClient } from "@redotech/admin-sdk/rpc/client";
import { Context, createContext, ReactNode, useMemo } from "react";

export const RedoAdminRpcClientContext: Context<
  RedoAdminRpcClient | undefined
> = createContext<RedoAdminRpcClient | undefined>(undefined);
RedoAdminRpcClientContext.displayName = "RedoAdminRpcClientContext";

export function RedoAdminRpcClientProvider({
  children,
  baseUrl,
  authToken,
}: {
  children: ReactNode | ReactNode[];
  baseUrl: string;
  authToken?: string;
}) {
  const redoAdminRpcClient = useMemo(() => {
    if (!authToken) {
      return undefined;
    }
    return createAdminRpcClient({
      baseURL: new URL(
        `${baseUrl.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl}/rpc`,
      ),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }, [baseUrl, authToken]);
  return (
    <RedoAdminRpcClientContext.Provider value={redoAdminRpcClient}>
      {children}
    </RedoAdminRpcClientContext.Provider>
  );
}
