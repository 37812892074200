import { useEventListener } from "@redotech/react-util/event";
import { useHandler } from "@redotech/react-util/hook";
import { unstable_useBlocker } from "react-router-dom";

export function usePrompt(message: string | null) {
  unstable_useBlocker(
    useHandler(() => (message !== null ? !confirm(message) : false)),
  );

  useEventListener(message !== null ? window : null, "beforeunload", (e) => {
    e.preventDefault();
    // https://bugs.chromium.org/p/chromium/issues/detail?id=866818
    e.returnValue = true;
  });

  // useEffect(() => {
  //   if (blocker.state === "blocked") {
  //     blocker.reset();
  //   }
  // }, [blocker]);
}
