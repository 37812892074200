import { rateTableSchema } from "@redotech/redo-model/rate-table/rate-table";
import { z } from "zod";

export const saveRateTable = {
  input: z.object({
    teamId: z.string(),
    rateTable: rateTableSchema,
  }),
  output: z.object({
    rateTables: z.array(rateTableSchema),
  }),
};
