import { Currency, getCurrencySymbol } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { DEFAULT_PRODUCT_TITLE } from "@redotech/redo-model/extended-warranty";
import { getResource } from "@redotech/redo-model/localization/resource";
import {
  CollectionSettings,
  ExtendedWarrantyCartToggleExperience,
  ExtendedWarrantyOffering,
  Team,
} from "@redotech/redo-model/team";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Card } from "@redotech/redo-web/card";
import { Divider } from "@redotech/redo-web/divider";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput, LabelTheme } from "@redotech/redo-web/labeled-input";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import {
  minMaxValidator,
  Pricing,
  pricingDefault,
  pricingFormValues,
  pricingRulesConvertTeamToValue,
  pricingRulesConvertValueToTeam,
} from "@redotech/redo-web/settings-elements/dynamic-pricing";
import { FormSwitch, Switch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import {
  groupInput,
  input,
  InputProvider,
  listInput,
  numberValidator,
} from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import classNames from "classnames";
import { Fragment, memo, SyntheticEvent, useContext, useState } from "react";
import { RedoAdminClientContext } from "../../../../client/context";
import { getCollections } from "../../../../client/team";
import { TeamContext } from "../../../team";
import { CollectionAutocomplete } from "../collections-autocomplete";

export const repairPricingForm = groupInput(
  {
    minPrice: input<string>({ validator: numberValidator({ min: 0.01 }) }),
    maxPrice: input<string>({ validator: numberValidator({ min: 0.01 }) }),
    collectionHandle: input<string>(),
    collectionTitle: input<string>(),
  },
  {},
);

export const repairPricingDefault = {
  minPrice: "1",
  maxPrice: "1",
  collectionHandle: "",
  collectionTitle: "",
};

export const warrantiesForm = groupInput(
  {
    enabled: input<boolean>(),
    customersCanStartClaims: input<boolean>(),
    registrationEnabled: input<boolean>(),
    repairFeeEnabled: input<boolean>(),
    labelCoverageEnabled: input<boolean>(),
    defaultRepairMinPrice: input<string>({
      validator: numberValidator({ min: 0.01 }),
    }),
    defaultRepairMaxPrice: input<string>({
      validator: numberValidator({ min: 0.01 }),
    }),
    repairPricingCustomCollections: input<string[]>({
      equal: arrayEqual(stringEqual),
    }),
    repairPricingRuleSet: listInput(
      () => repairPricingForm,
      () => repairPricingDefault,
      (repairPricing) => repairPricing.collectionHandle,
    ),
    warrantyRegistrationCollection: input<string | undefined>(),
  },
  {},
);

export type WarrantiesForm = InputProvider.Form<typeof warrantiesForm>;

export type WarrantiesValue = InputProvider.Value<typeof warrantiesForm>;

export const warrantiesDefault: WarrantiesValue = {
  enabled: false,
  defaultRepairMaxPrice: "1",
  defaultRepairMinPrice: "1",
  customersCanStartClaims: false,
  registrationEnabled: false,
  repairFeeEnabled: false,
  labelCoverageEnabled: false,
  warrantyRegistrationCollection: undefined,
  repairPricingCustomCollections: [],
  repairPricingRuleSet: [],
};

export const Warranties = memo(function Warranties({
  input,
}: {
  input: WarrantiesForm;
}) {
  const {
    enabled,
    registrationEnabled,
    repairFeeEnabled,
    customersCanStartClaims,
    warrantyRegistrationCollection,
    defaultRepairMaxPrice,
    defaultRepairMinPrice,
    repairPricingCustomCollections,
    repairPricingRuleSet,
  } = input.inputs;

  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);

  const [searchString, setSearchString] = useState<string>();

  const collectionsLoad = useLoad(async () => {
    if (!team) {
      return { collections: [] };
    }

    return { collections: await getCollections(client, { teamId: team._id }) };
  }, []);

  const collections = collectionsLoad.value?.collections ?? [];

  const selectedCollection = collections.find(
    (collection) => collection.id === warrantyRegistrationCollection.value,
  );

  const searchedCollections = searchString
    ? collections.filter((collection) =>
        collection.title
          .toLowerCase()
          .includes(searchString?.toLowerCase() || ""),
      )
    : collections;

  const collectionsLoading = collectionsLoad.pending && !collectionsLoad.value;

  const handleToggle = useHandler((value: boolean) => {
    enabled.setValue(value);
    if (!value) {
      repairFeeEnabled.setValue(false);
      customersCanStartClaims.setValue(false);
      registrationEnabled.setValue(false);
    }
  });

  const handleValueChange = useHandler(
    (collection: { id: string; title: string; handle: string } | null) => {
      warrantyRegistrationCollection.setValue(collection?.id);
    },
  );

  const handleInputChange = useHandler(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchString(value);
    },
  );

  const currencyDisplay = getCurrencySymbol(
    team?._shopify.currency || Currency.USD,
  );

  const handleSetCustomCollections = useHandler(
    (collections: { id: string; title: string; handle: string }[]) => {
      repairPricingCustomCollections.setValue(
        collections.map((collection) => collection.handle),
      );
      repairPricingRuleSet.setValue(
        collections.map((collection) => ({
          minPrice: "1",
          maxPrice: "1",
          collectionHandle: collection.handle,
          collectionTitle: collection.title,
        })),
      );
    },
  );

  return (
    <Card title="Warranties">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <LabeledInput
            description="Display the warranty flow to merchants"
            label="Warranty flow"
          >
            <Switch onChange={handleToggle} value={enabled.value} />
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={customersCanStartClaims}
            label="Allows customers to start claims"
          >
            Show the '{getResource({ variant: "warranty" }).PORTAL_BUTTON_TEXT}'
            button on the customer portal.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch input={registrationEnabled} label="Warranty registration">
            Allow customers to register their warranties and submit claims on
            them.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <LabeledInput label="Registration collection">
            <Autocomplete
              getLabel={(collection) => collection.title}
              keyFn={(collection) => collection.handle}
              noOptionsText={collectionsLoading ? "Loading..." : "No options"}
              onInputChange={handleInputChange}
              options={searchedCollections}
              value={selectedCollection ?? null}
              valueChange={handleValueChange}
            >
              {(collection) => collection.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={input.inputs.labelCoverageEnabled}
            label="Use return coverage to cover warranty labels"
          >
            Allow return coverage to cover the cost of warranty labels.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12)}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>Repair fees</strong>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch input={repairFeeEnabled} label="Repair fees enabled">
            Charge customers a flat rate for repairs.
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <strong>Default pricing</strong>
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormTextInput
            input={defaultRepairMinPrice}
            label="Min price"
            labelTheme={LabelTheme.THIN}
            min={0.01}
            prefix={currencyDisplay}
            step={0.01}
            type="number"
          />
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormTextInput
            input={defaultRepairMaxPrice}
            label="Max price"
            labelTheme={LabelTheme.THIN}
            min={0.01}
            prefix={currencyDisplay}
            step={0.01}
            type="number"
          />
        </div>
        <div className={gridCss.span12}>
          <LabeledInput
            description="Select the collections that will have a custom repair fee."
            label="Custom collections"
          >
            <CollectionAutocomplete
              keyFn={(collection) => collection.handle}
              setValueCollections={handleSetCustomCollections}
              value={repairPricingCustomCollections.value ?? []}
            />
          </LabeledInput>
        </div>
        {repairPricingRuleSet.inputs?.map((repairPricing, index) => (
          <div className={gridCss.span12} key={index}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <strong>{repairPricing.value.collectionTitle}</strong>
              </div>
              <div className={classNames(gridCss.span12, gridCss.span6L)}>
                <FormTextInput
                  input={repairPricing.inputs.minPrice}
                  label="Min price"
                  labelTheme={LabelTheme.THIN}
                  min={0.01}
                  prefix={currencyDisplay}
                  step={0.01}
                  type="number"
                />
              </div>
              <div className={classNames(gridCss.span12, gridCss.span6L)}>
                <FormTextInput
                  input={repairPricing.inputs.maxPrice}
                  label="Max price"
                  labelTheme={LabelTheme.THIN}
                  min={0.01}
                  prefix={currencyDisplay}
                  step={0.01}
                  type="number"
                />
              </div>
            </div>
          </div>
        ))}
      </section>
    </Card>
  );
});

export const extendedWarrantyOfferingForm = groupInput(
  {
    timeId: input<string>(),
    timeTitle: input<string>(),
    daysExtended: input<string>(),
    ...pricingFormValues,
  },
  { validator: minMaxValidator },
);

export const extendedWarrantyCollectionForm = groupInput({
  collectionHandle: input<string>(),
  collectionName: input<string>(),
  validOfferings: input<string[]>({ equal: arrayEqual(stringEqual) }),
  offerings: listInput(
    () => extendedWarrantyOfferingForm,
    () => ({
      timeId: "",
      timeTitle: "",
      daysExtended: "0",
      collectionHandle: "",
      collectionName: "",
      ...pricingDefault,
    }),
    (_, index) => index,
  ),
});

export const extendedWarrantySettingsForm = groupInput({
  enabled: input<boolean>(),
  variantSelector: input<string>(),
  validCollections: input<string[]>({ equal: arrayEqual(stringEqual) }),
  productTitle: input<string>(),
  cartToggleExperience: input<string>(),
  extendedWarrantyRevSharePercentage: input<string>(),
  collectionSettings: listInput(
    () => extendedWarrantyCollectionForm,
    () => ({
      collectionHandle: "",
      collectionName: "",
      validOfferings: [],
      offerings: [],
    }),
    (_, index) => index,
  ),
});

export type ExtendedWarrantyCollectionValue = InputProvider.Value<
  typeof extendedWarrantyCollectionForm
>;
export type ExtendedWarrantyCollectionForm = InputProvider.Form<
  typeof extendedWarrantyCollectionForm
>;

export type ExtendedWarrantySettingsForm = InputProvider.Form<
  typeof extendedWarrantySettingsForm
>;

export type ExtendedWarrantySettingsValue = InputProvider.Value<
  typeof extendedWarrantySettingsForm
>;

export const extendedWarrantySettingsDefault = {
  enabled: false,
  extendedWarrantyRevSharePercentage: "0",
  variantSelector: "",
  validCollections: [],
  productTitle: "",
  cartToggleExperience: ExtendedWarrantyCartToggleExperience.TOGGLE,
  collectionSettings: [],
};

export function extendedWarrantyTeamToFormValue(
  team?: Team,
): ExtendedWarrantySettingsValue {
  return {
    enabled: team?.settings.extendedWarranties?.enabled || false,
    variantSelector: team?.settings.extendedWarranties?.variantSelector || "",
    validCollections: team?.settings.extendedWarranties?.validCollections || [],
    productTitle: team?.settings.extendedWarranties?.productTitle || "",
    cartToggleExperience:
      team?.settings.extendedWarranties?.cartToggleExperience ||
      ExtendedWarrantyCartToggleExperience.TOGGLE,
    extendedWarrantyRevSharePercentage: team?.settings.extendedWarranties
      ?.revSharePercentage
      ? String(team?.settings.extendedWarranties?.revSharePercentage * 100)
      : "0",
    collectionSettings:
      team?.settings.extendedWarranties?.collectionSettings?.map(
        (collectionSettings) => ({
          collectionHandle: collectionSettings.collectionHandle,
          collectionName: collectionSettings.collectionName || "",
          validOfferings: collectionSettings.validOfferings || [],
          offerings: collectionSettings.offerings.map((offering) => ({
            timeId: offering.timeId,
            daysExtended: offering.daysExtended
              ? String(offering.daysExtended)
              : "0",
            timeTitle: offering.timeTitle,
            minPrice: offering.minPrice,
            maxPrice: offering.maxPrice,
            percentage: offering.percentage,
            internationalPricingEnabled: offering.internationalPricingEnabled,
            pricingRuleSet: pricingRulesConvertTeamToValue([
              offering.pricingRuleSet,
            ]),
          })),
        }),
      ) || [],
  };
}

const LIFETIME_DAYS = 36500;

export function extendedWarrantyFormValueToTeam(
  extendedWarranties: ExtendedWarrantySettingsValue,
) {
  return {
    enabled: extendedWarranties.enabled,
    variantSelector: extendedWarranties.variantSelector,
    validCollections: extendedWarranties.validCollections,
    productTitle:
      extendedWarranties.productTitle === ""
        ? undefined
        : extendedWarranties.productTitle,
    cartToggleExperience:
      extendedWarranties.cartToggleExperience as ExtendedWarrantyCartToggleExperience,
    revSharePercentage:
      Number(extendedWarranties.extendedWarrantyRevSharePercentage) / 100,
    collectionSettings: extendedWarranties.collectionSettings.map(
      (collectionSettings) => ({
        ...collectionSettings,
        offerings: collectionSettings.offerings.map((offering) => ({
          ...offering,
          daysExtended:
            offering.timeId === "lifetime"
              ? LIFETIME_DAYS
              : parseInt(offering.daysExtended),
          pricingRuleSet: pricingRulesConvertValueToTeam(
            offering.pricingRuleSet,
          )?.[0],
        })) as ExtendedWarrantyOffering[],
      }),
    ) as CollectionSettings[],
  };
}

const extendedWarrantyCartToggleExperience: readonly ExtendedWarrantyCartToggleExperience[] =
  [
    ExtendedWarrantyCartToggleExperience.TOGGLE,
    ExtendedWarrantyCartToggleExperience.MODAL,
  ];

export const ExtendedWarranties = memo(function ExtendedWarranties({
  input,
}: {
  input: ExtendedWarrantySettingsForm;
}) {
  const team = useContext(TeamContext);
  const hasSufficientAccess = team?.accessScope.includes(
    "write_cart_transforms",
  );

  const {
    enabled,
    extendedWarrantyRevSharePercentage,
    variantSelector,
    validCollections,
    collectionSettings,
  } = input.inputs;

  const setValue = useHandler(
    (collections: { id: string; title: string; handle: string }[]) => {
      input.inputs.validCollections.setValue(
        collections.map((collection) => collection.handle),
      );
      input.inputs.collectionSettings.setValue(
        collections.map((collection) => ({
          collectionHandle: collection.handle,
          collectionName: collection.title,
          validOfferings:
            input.value.collectionSettings.find(
              (collectionSettings) =>
                collectionSettings.collectionHandle === collection.handle,
            )?.validOfferings || [],
          offerings:
            input.value.collectionSettings.find(
              (collectionSettings) =>
                collectionSettings.collectionHandle === collection.handle,
            )?.offerings || [],
        })),
      );
    },
  );

  return (
    <Card title="Extended warranties">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            disabled={!hasSufficientAccess}
            input={enabled}
            label="Coverage Enabled"
          >
            Enable customers to buy extended warranties
          </FormSwitch>
          {!hasSufficientAccess && (
            <Text fontSize="sm" pt="sm">
              The team does not have sufficient access to enable extended
              warranties. Please ask an admin to update the team's permissions
              through the merchant portal.
            </Text>
          )}
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormTextInput
            description="Percentage of total extended warranty purchase price that Redo keeps. Don't touch this unless you have talked to Dan."
            input={extendedWarrantyRevSharePercentage}
            label="Extended warranty rev share percentage"
            max={100}
            min={0}
            step={1}
            suffix="%"
            type="number"
          />
        </div>
        <div className={gridCss.span12}>
          <FormSelectDropdown
            input={input.inputs.cartToggleExperience}
            label="Cart toggle experience"
            options={extendedWarrantyCartToggleExperience}
            placeholder="Default"
          >
            {(option) => option}
          </FormSelectDropdown>
        </div>

        <div className={gridCss.span12}>
          <FormTextInput
            description="This is the title of the extended warranty product that will be created on the store."
            input={input.inputs.productTitle}
            label="Product title"
            placeholder={DEFAULT_PRODUCT_TITLE}
          />
        </div>
        <div className={gridCss.span12}>
          <FormTextInput
            description="You can use %variant_id% as placeholder."
            input={variantSelector}
            label="Variant selector for the extended warranty toggle."
          />
        </div>
        <div className={gridCss.span12}>
          <LabeledInput
            description="Coverage will be offered for these Shopify collections. The first 100 collections are shown by default. To access more, search by title."
            label="Included collections"
          >
            <CollectionAutocomplete
              keyFn={(collection) => collection.handle}
              setValueCollections={setValue}
              value={validCollections.value}
            />
          </LabeledInput>
        </div>
        {collectionSettings.value.map((collectionSettings, index) => (
          <ExtendedWarrantiesCollection
            input={input.inputs.collectionSettings.inputs[index]}
            key={collectionSettings.collectionHandle}
          />
        ))}
      </section>
    </Card>
  );
});

export const ExtendedWarrantiesCollection = memo(
  function ExtendedWarrantiesCollection({
    input,
  }: {
    input: ExtendedWarrantyCollectionForm;
  }) {
    const tileOptions: { id: string; title: string; handle: string }[] = [];
    for (let i = 1; i <= 10; i++) {
      tileOptions.push({
        id: i.toString(),
        title: i === 1 ? "1 year" : `${i} years`,
        handle: i.toString(),
      });
    }
    tileOptions.push({
      id: "lifetime",
      title: "Lifetime",
      handle: "lifetime",
    });
    const team = useContext(TeamContext);
    const selectedTileOptions = tileOptions.filter((option) =>
      input.value.offerings.some((offering) => offering.timeId === option.id),
    );

    const handleValueChange = useHandler(
      (tileOptions: { id: string; title: string; handle: string }[]) => {
        input.inputs.validOfferings.setValue(
          tileOptions.map((option) => option.id),
        );
        input.inputs.offerings.setValue(
          tileOptions.map((option, index) => ({
            timeId: option.id,
            daysExtended:
              option.id === "lifetime"
                ? String(LIFETIME_DAYS)
                : String(parseInt(option.id) * 365),
            timeTitle: option.title,
            collectionHandle: input.value.collectionHandle,
            collectionName: input.value.collectionName,
            ...(input.inputs.offerings.value.some(
              (offering) => offering.timeId === option.id,
            )
              ? input.inputs.offerings.inputs[index].value
              : pricingDefault),
          })),
        );
      },
    );

    return (
      <>
        <div className={classNames(gridCss.span12)}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>{input.value.collectionName}</strong>
        </div>

        <div className={gridCss.span12}>
          <LabeledInput
            description="These are the tiles offered to customers."
            label={`Warranty offerings for ${input.value.collectionName}`}
          >
            <Autocomplete
              getLabel={(option) => option.title}
              keyFn={(option) => option.handle}
              multiple
              options={tileOptions}
              value={selectedTileOptions}
              valueChange={handleValueChange}
            >
              {(option) => option.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        {input.value.offerings.map((offering, index) => (
          <Fragment key={offering.timeTitle}>
            <div className={gridCss.span12}>
              <Divider />
            </div>
            <div className={gridCss.span12}>
              <strong>{offering.timeTitle}</strong>
            </div>
            <div className={gridCss.span12}>
              <FormTextInput
                disabled={offering.timeId === "lifetime"}
                input={input.inputs.offerings.inputs[index].inputs.daysExtended}
                label="Days extended"
                labelTheme={LabelTheme.THIN}
                min={0}
                step={1}
                type="number"
              />
            </div>
            <div className={gridCss.span12}>
              <Pricing
                adminApp
                canEditSettings
                currencyDisplay={getCurrencySymbol(
                  team?._shopify.currency || Currency.USD,
                )}
                input={input.inputs.offerings.inputs[index]}
              />
            </div>
          </Fragment>
        ))}
      </>
    );
  },
);
