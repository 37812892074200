import { z } from "zod";
import { zExt } from "../common/zod-util";

export enum PhoneNumberUseCase {
  Marketing = "marketing",
  Support = "support",
}

export enum PhoneNumberProvider {
  Telnyx = "telnyx",
  Bandwidth = "bandwidth",
}

export enum TextMessagingVerificationStatus {
  Unverified = "unverified",
  Pending = "pending",
  Verified = "verified",
}

interface VoiceMetadata {
  billingGroupId: string;
  sipConnectionId: string;
}

export const PhoneNumberSchema = z.object({
  _id: zExt.objectId(),
  number: z.string(),
  team: zExt.objectId(),
  provider: z.nativeEnum(PhoneNumberProvider),
  useCase: z.nativeEnum(PhoneNumberUseCase),
  messagingMetadata: z
    .object({
      verificationStatus: z.nativeEnum(TextMessagingVerificationStatus),
    })
    .optional(),
  voiceMetadata: z
    .object({
      billingGroupId: z.string(),
      sipConnectionId: z.string(),
    })
    .optional(),
});

export type PhoneNumber = z.infer<typeof PhoneNumberSchema>;

export type MessagingMetadata = NonNullable<PhoneNumber["messagingMetadata"]>;

export type VoiceEnabledPhoneNumber = PhoneNumber & {
  voiceMetadata: VoiceMetadata;
};

export function isVoiceEnabledPhoneNumber(
  phoneNumber: PhoneNumber,
): phoneNumber is VoiceEnabledPhoneNumber {
  return !!phoneNumber.voiceMetadata;
}
