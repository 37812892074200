import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import {
  LabelOrientation,
  LabelPosition,
} from "@redotech/redo-web/labeled-input";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo } from "react";

export const customerAccountsGeneralForm = groupInput({
  enabled: input<boolean>(),
  multipassSecret: input<string>(),
  loyaltyEnabled: input<boolean>(),
  webPixelEnabled: input<boolean>(),
});

export type CustomerAccountsGeneralForm = InputProvider.Form<
  typeof customerAccountsGeneralForm
>;

export type CustomerAccountsGeneralValue = InputProvider.Value<
  typeof customerAccountsGeneralForm
>;

export const customerAccountsGeneralDefault: CustomerAccountsGeneralValue = {
  enabled: false,
  multipassSecret: "",
  loyaltyEnabled: false,
  webPixelEnabled: false,
};

export const CustomerAccountsGeneralCard = memo(
  function CustomerAccountsGeneralCard({
    input,
  }: {
    input: CustomerAccountsGeneralForm;
  }) {
    const { enabled, multipassSecret, webPixelEnabled } = input.inputs;

    return (
      <Card title="Customer Accounts">
        <section className={gridCss.grid}>
          <div className={gridCss.span12}>
            <div className={gridCss.grid}>
              <div className={classNames(gridCss.span6L, gridCss.span12)}>
                <FormSwitch
                  input={enabled}
                  label="Enabled"
                  orientation={LabelOrientation.VERTICAL}
                  position={LabelPosition.RIGHT}
                >
                  Enable Customer Accounts product
                </FormSwitch>
              </div>
            </div>
          </div>
          <div className={gridCss.span12}>
            <div className={gridCss.grid}>
              <div className={classNames(gridCss.span6L, gridCss.span12)}>
                <FormTextInput
                  description="If the merchant is on Shopify Plus, enter the multipass secret here. It can be found in their Shopify Admin under Settings > Customer accounts > Multipass."
                  input={multipassSecret}
                  label="Multipass secret"
                />
              </div>
            </div>
          </div>
          {/* <div className={gridCss.span12}>
            <div className={gridCss.grid}>
              <div className={classNames(gridCss.span6L, gridCss.span12)}>
                <FormSwitch input={loyaltyEnabled} label="Loyalty enabled">
                  Enable Customer Accounts loyalty
                </FormSwitch>
              </div>
            </div>
          </div> */}
          <div className={gridCss.span12}>
            <div className={gridCss.grid}>
              <div className={classNames(gridCss.span6L, gridCss.span12)}>
                <FormSwitch
                  input={webPixelEnabled}
                  label="Web pixel enabled"
                  orientation={LabelOrientation.VERTICAL}
                  position={LabelPosition.RIGHT}
                >
                  Activate the web pixel to track customer shopping activity
                </FormSwitch>
              </div>
            </div>
          </div>
        </section>
      </Card>
    );
  },
);
