import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo, useContext } from "react";
import { TeamContext } from "../../team";

export const orderTrackingBillingForm = groupInput({
  enabled: input<boolean>(),
  continueIfNotAccepted: input<boolean>(),
  pricePerOrder: input<string>(),
  freeOrdersPerMonth: input<string>(),
  freeUntilDateEnabled: input<boolean>(),
  freeUntilDate: input<string>(),
  texts: groupInput({
    enabled: input<boolean>(),
    pricePerSms: input<string>(),
    pricePerMms: input<string>(),
  }),
});

export type OrderTrackingBillingForm = InputProvider.Form<
  typeof orderTrackingBillingForm
>;

export type OrderTrackingBillingValue = InputProvider.Value<
  typeof orderTrackingBillingForm
>;

export const orderTrackingBillingDefault: OrderTrackingBillingValue = {
  enabled: false,
  continueIfNotAccepted: false,
  pricePerOrder: (0.06).toString(),
  freeOrdersPerMonth: (100).toString(),
  freeUntilDateEnabled: false,
  freeUntilDate: "",
  texts: {
    enabled: false,
    pricePerSms: (0.01).toString(),
    pricePerMms: (0.03).toString(),
  },
};

export const OrderTrackingBillingCard = memo(function OrderTrackingBillingCard({
  input,
}: {
  input: OrderTrackingBillingForm;
}) {
  const {
    enabled,
    pricePerOrder,
    freeOrdersPerMonth,
    freeUntilDateEnabled,
    freeUntilDate,
    texts,
    continueIfNotAccepted,
  } = input.inputs;
  const team = useContext(TeamContext);
  const activeSubscription = team?.settings?.shopifySubscription?.[0];
  const isEnabled = team?.settings.orderTracking?.billing?.enabled;

  return (
    <Card title="Order Tracking Billing">
      <section className={(gridCss.grid, gridCss.gap1)}>
        <Flex className={gridCss.span6L} dir="column" gap="xl">
          {activeSubscription && isEnabled && (
            <div>
              <h3>Active Subscription</h3>
              <p>Name: {activeSubscription?.name}</p>
              <p>Status: {activeSubscription?.status}</p>
            </div>
          )}
          <FormSwitch input={enabled} label="Enabled">
            Enabling billing will prompt the merchant to accept a usage-based
            subscription based on the settings below. Disabling billing will not
            affect the current subscription. Canceling the subscription is
            possible from the merchant dashboard "Billing" page
          </FormSwitch>
          {enabled?.value && (
            <>
              <FormSwitch
                input={freeUntilDateEnabled}
                label="Free Until Date"
              />
              <FormTextInput
                disabled={!freeUntilDateEnabled.value}
                input={freeUntilDate}
                label="Date"
                type="date"
              />
              <Text as="div" fontSize="sm" textColor="tertiary">
                Orders will be free until the specified date, after which the
                price per order will be charged and the customer will be
                transitioned to a paid subscription
              </Text>
              <FormSwitch
                input={continueIfNotAccepted}
                label="Continue if billing not accepted"
              >
                Enable the tracking page and send emails even if billing is not
                enabled or accepted
              </FormSwitch>
              <FormTextInput
                input={pricePerOrder}
                label="Price per order"
                max={1}
                min={0}
                prefix="$"
                step={0.001}
                type="number"
              />
              <FormTextInput
                input={freeOrdersPerMonth}
                label="Free orders per month"
                min={0}
                step={1}
                type="number"
              />
              <FormSwitch input={texts.inputs.enabled} label="Texts">
                Enable billing for SMS/MMS updates
              </FormSwitch>

              {texts.inputs.enabled.value && (
                <>
                  <FormTextInput
                    input={texts.inputs.pricePerSms}
                    label="Price per SMS"
                    max={1}
                    min={0}
                    prefix="$"
                    step={0.001}
                    type="number"
                  />
                  <FormTextInput
                    input={texts.inputs.pricePerMms}
                    label="Price per MMS"
                    max={1}
                    min={0}
                    prefix="$"
                    step={0.001}
                    type="number"
                  />
                </>
              )}
            </>
          )}
        </Flex>
      </section>
    </Card>
  );
});
