// extracted by mini-css-extract-plugin
export var active = "_p-3";
export var activeWrapper = "_p-0";
export var button = "_p-1";
export var collapsed = "_p-i";
export var divider = "_p-7";
export var dividerSection = "_p-6";
export var dropdown = "_p-8";
export var dropdownChevron = "_p-a";
export var expand = "_p-9";
export var fullscreen = "_p-r";
export var icon = "_p-n";
export var label = "_p-5 _6-0";
export var labelWithBubble = "_p-p _6-0";
export var logo = "_p-j";
export var logoSection = "_p-m";
export var main = "_p-q";
export var menu = "_p-b";
export var menuHeader = "_p-c _6-0";
export var menuItem = "_p-d _6-0";
export var nav = "_p-e";
export var noBackground = "_p-2";
export var option = "_p-4";
export var options = "_p-g";
export var optionsContainer = "_p-f";
export var page = "_p-h";
export var rotate = "_p-o";
export var section = "_p-k";
export var settings = "_p-t";
export var subitem = "_p-s";
export var subitemActive = "_p-x";
export var subitemLabel = "_p-w";
export var subitemLine = "_p-v";
export var subitemLineContainer = "_p-u";
export var subsection = "_p-l";