export const shippingCarriers = [
  { name: "UPS", value: "UPS" },
  { name: "Canada Post", value: "CanadaPost" },
  { name: "Fed Ex Old", value: "FedExDefault" },
  { name: "Fed Ex", value: "FedEx" },
  { name: "Fed Ex Smart Post", value: "FedExSmartPost" },
  { name: "USPS Returns", value: "USPSReturns" },
  { name: "USPS", value: "USPS" },
  { name: "AusPost", value: "AustraliaPost" },
];
