import { Onboarding } from "@redotech/redo-model/team";
import { RedoAdminClient } from ".";

/**
 * GET /teams/:teamId/onboarding
 */
export async function getOnboarding(
  client: RedoAdminClient,
  { teamId, signal }: { teamId: string; signal?: AbortSignal },
): Promise<Onboarding> {
  const response = await client.client.get(
    `teams/${encodeURIComponent(teamId)}/onboarding`,
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}
