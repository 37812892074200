import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  isWeightByZoneRateTable,
  RateTable,
  WeightByZoneRateTable,
} from "@redotech/redo-model/rate-table/rate-table";
import {
  FedexServiceLevel,
  RateTableCarrier,
  RateTableServiceLevel,
  RateTableServiceLevelsMapping,
} from "@redotech/redo-model/rate-table/rate-table-mapping";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import CopyIcon from "@redotech/redo-web/icon-old/copy.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormTextInput } from "@redotech/redo-web/text-input";
import {
  groupInput,
  input,
  InputProvider,
  listInput,
  nonEmptyValidator,
} from "@redotech/ui/form";
import { memo, useEffect, useMemo, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../../app/redo-admin-rpc-client-provider";
import { TeamContext } from "../../../team";
import { defaultNewRateTable } from "./default-new-rate-table";
import { OutboundLabelRateTableForm } from "./rate-table";
import {
  tableDataToMinMaxValues,
  tableDataToRateData,
  tableDataToZones,
} from "./rate-table-utils";

const rateCardKeyForm = groupInput({
  selectedCarrier: input<RateTableCarrier>(),
  selectedServiceLevel: input<RateTableServiceLevel>(),
});

export type RateCardKeyForm = InputProvider.Form<typeof rateCardKeyForm>;
export type RateCardKeyFormValue = InputProvider.Value<typeof rateCardKeyForm>;
export const rateCardKeyFormDefault: RateCardKeyFormValue = {
  selectedCarrier: RateTableCarrier.FEDEX,
  selectedServiceLevel: FedexServiceLevel.SmartPost,
};

const rateCardForm = groupInput({
  defaultValue: input<string>(),
  tableData: listInput(
    () => inputRow,
    () => [],
    (_, index) => index,
  ),
});

const inputRow = listInput(
  () => input<string>({ validator: nonEmptyValidator }),
  () => "",
  (_, index) => index,
);

export type RateCardForm = InputProvider.Form<typeof rateCardForm>;
export type RateCardValue = InputProvider.Value<typeof rateCardForm>;
export const rateCardDefault: RateCardValue = {
  defaultValue: "5",
  tableData: [],
};

export const OutboundLabelRateCardCard = memo(
  function OutboundLabelRateCardCard() {
    const team = useRequiredContext(TeamContext);
    const client = useRequiredContext(RedoAdminRpcClientContext);

    const keyFormInput = useInput(rateCardKeyForm, rateCardKeyFormDefault);

    const [savePending, setSavePending] = useState(false);

    const [rateCardLoad, triggerRateCardLoad] = useTriggerLoad<
      WeightByZoneRateTable[]
    >(async () => {
      const tables = await client.getRateTables({ teamId: team._id });
      const filtered: WeightByZoneRateTable[] = tables.rateTables.filter(
        (table: RateTable) => isWeightByZoneRateTable(table),
      );
      return filtered;
    });

    useEffect(() => {
      triggerRateCardLoad();
    }, [team, client]);

    // Service levels depends on the carrier
    const serviceLevelOptions = useMemo(() => {
      return RateTableServiceLevelsMapping[keyFormInput.value.selectedCarrier];
    }, [keyFormInput.value.selectedCarrier]);

    // Select the first service level for that carrier when the service level options change
    useEffect(() => {
      keyFormInput.setValue({
        ...keyFormInput.value,
        selectedServiceLevel: serviceLevelOptions[0],
      });
    }, [serviceLevelOptions]);

    const rateTable = useMemo(() => {
      return rateCardLoad.value?.find(
        (rateTable: RateTable) =>
          rateTable.carrier === keyFormInput.value.selectedCarrier &&
          rateTable.serviceLevel === keyFormInput.value.selectedServiceLevel,
      );
    }, [
      rateCardLoad.value,
      keyFormInput.value.selectedCarrier,
      keyFormInput.value.selectedServiceLevel,
    ]);

    const rateTableDataInput = useInput(rateCardForm, rateCardDefault);

    const saveDisabled = savePending || rateTableDataInput.allErrors.length > 0;

    const saveRateCard = async () => {
      if (rateTable) {
        setSavePending(true);

        const updatedRateTable: RateTable = {
          carrier: keyFormInput.value.selectedCarrier,
          serviceLevel: keyFormInput.value.selectedServiceLevel,
          data: tableDataToRateData(rateTableDataInput.value.tableData),
          xAxis: {
            values: tableDataToZones(rateTableDataInput.value.tableData),
            kind: rateTable.xAxis.kind,
          },
          yAxis: {
            values: tableDataToMinMaxValues(rateTableDataInput.value.tableData),
            kind: rateTable.yAxis.kind,
            units: rateTable.yAxis.units,
          },
          defaultValue: rateTableDataInput.value.defaultValue,
        };

        try {
          await client.saveRateTable({
            rateTable: updatedRateTable,
            teamId: team._id,
          });
        } catch (error) {
          toast("Failed to save rate table", {
            variant: "error",
          });
          console.error(error);
        } finally {
          setSavePending(false);
        }
      }
    };

    const addBottomRow = () => {
      const bottomRow =
        rateTableDataInput.value.tableData.at(-1)?.map((_) => "0") ?? [];
      rateTableDataInput.inputs.tableData.setValue([
        ...rateTableDataInput.value.tableData,
        bottomRow,
      ]);
    };

    const addRightColumn = () => {
      const newColumn =
        rateTableDataInput.value.tableData
          .map((row) => row.at(-1))
          .map((_) => "0") ?? [];
      const newCharges = rateTableDataInput.value.tableData.map(
        (row, index) => [...row, newColumn.at(index) ?? "0"],
      );
      rateTableDataInput.inputs.tableData.setValue(newCharges);
    };

    const deleteRow = (index: number) => {
      rateTableDataInput.inputs.tableData.setValue(
        rateTableDataInput.value.tableData.filter((_, i) => i !== index),
      );
    };

    const deleteColumn = (columnIndex: number) => {
      rateTableDataInput.inputs.tableData.setValue(
        rateTableDataInput.value.tableData.map((row) =>
          row.filter((_, i) => i !== columnIndex),
        ),
      );
    };

    const addRateTable = async () => {
      await client.saveRateTable({
        rateTable: defaultNewRateTable(
          keyFormInput.value.selectedCarrier,
          keyFormInput.value.selectedServiceLevel,
        ),
        teamId: team._id,
      });
      triggerRateCardLoad();
    };

    const copyTable = () => {
      const data = rateTableDataInput.value.tableData.map((row) => [...row]);
      // Make sure we copy a blank cell for the upper left cell, it's useless
      data[0][0] = "";
      const excelData = data.map((row) => row.join("\t")).join("\n");
      void navigator.clipboard.writeText(excelData);
    };

    return (
      <Card title="Outbound rate tables">
        <Flex dir="row" justify="space-between">
          <Flex dir="row" justify="space-between" w="full">
            <Flex dir="row">
              <FormSelectDropdown
                input={keyFormInput.inputs.selectedCarrier}
                label=""
                options={Object.values(RateTableCarrier)}
              >
                {(s) => s}
              </FormSelectDropdown>
              <FormSelectDropdown
                input={keyFormInput.inputs.selectedServiceLevel}
                label=""
                options={serviceLevelOptions}
              >
                {(s) => s}
              </FormSelectDropdown>
              {!rateTable ? (
                <RedoButton
                  hierarchy={RedoButtonHierarchy.PRIMARY}
                  onClick={addRateTable}
                  size={RedoButtonSize.LARGE}
                  text="Create"
                  theme={RedoButtonTheme.NORMAL}
                />
              ) : (
                <RedoButton
                  disabled={saveDisabled}
                  hierarchy={RedoButtonHierarchy.PRIMARY}
                  onClick={saveRateCard}
                  pending={savePending}
                  size={RedoButtonSize.LARGE}
                  text="Save table"
                  theme={RedoButtonTheme.NORMAL}
                />
              )}
            </Flex>
            {rateTable && (
              <Flex dir="row">
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconLeading={CopyIcon}
                  onClick={copyTable}
                  size={RedoButtonSize.LARGE}
                  text="Copy table"
                  theme={RedoButtonTheme.NORMAL}
                />
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconLeading={PlusIcon}
                  onClick={addRightColumn}
                  size={RedoButtonSize.LARGE}
                  text="Add column"
                  theme={RedoButtonTheme.NORMAL}
                />
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconLeading={PlusIcon}
                  onClick={addBottomRow}
                  size={RedoButtonSize.LARGE}
                  text="Add row"
                  theme={RedoButtonTheme.NORMAL}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        {rateTable && (
          <Flex dir="column">
            <Flex dir="row">
              <OutboundLabelRateTableForm
                deleteColumn={deleteColumn}
                deleteRow={deleteRow}
                input={rateTableDataInput}
                rateTable={rateTable}
              />
            </Flex>
            <Flex dir="column" pt="md" w="xs">
              <FormTextInput
                input={rateTableDataInput.inputs.defaultValue}
                label="Default value (used if no match is found in the table)"
                min={0}
                type="number"
              />
            </Flex>
          </Flex>
        )}
      </Card>
    );
  },
);
