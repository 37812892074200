// extracted by mini-css-extract-plugin
export var alignBaseline = "_7-8";
export var alignCenter = "_7-4";
export var alignFlexEnd = "_7-6";
export var alignFlexStart = "_7-5";
export var alignSelfBaseline = "_7-d";
export var alignSelfCenter = "_7-9";
export var alignSelfFlexEnd = "_7-b";
export var alignSelfFlexStart = "_7-a";
export var alignSelfStretch = "_7-c";
export var alignStretch = "_7-7";
export var column = "_7-3";
export var flex = "_7-0";
export var justifyCenter = "_7-e";
export var justifyFlexEnd = "_7-g";
export var justifyFlexStart = "_7-f";
export var justifySpaceAround = "_7-i";
export var justifySpaceBetween = "_7-h";
export var justifySpaceEvenly = "_7-j";
export var reverse = "_7-2";
export var row = "_7-1";
export var wrapNowrap = "_7-l";
export var wrapWrap = "_7-k";
export var wrapWrapReverse = "_7-m";