import * as classnames from "classnames";
import { memo } from "react";
import { Card } from "../../card";
import { Flex } from "../../flex";
import { RadioButton } from "../../radio";
import { Text } from "../../text";
import { RedoCheckbox } from "./redo-checkbox";
import * as css from "./redo-checkbox-card.module.css";

export const checkboxCardInputTypes = ["radio", "checkbox"] as const;
export type CheckboxCardInputType = (typeof checkboxCardInputTypes)[number];

export interface RedoCheckboxCardProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  checked: boolean;
  onClick: () => void;
  borderColor?: string;
  inputType?: CheckboxCardInputType;
}

export const RedoCheckboxCard = memo(function RedoCheckboxCardProps({
  title,
  icon,
  checked,
  onClick,
  inputType,
  children,
}: RedoCheckboxCardProps) {
  return (
    <Card
      className={classnames(css.checkboxCard, {
        [css.checkboxCardSelected]: checked,
      })}
      onClick={onClick}
    >
      <Flex gap="lg">
        {icon && <div className={css.cardIcon}>{icon}</div>}
        <Flex align="flex-start" dir="column" flex={1} gap="xs">
          <Text fontSize="md" fontWeight="bold">
            {title}
          </Text>
          <Text color="secondary" fontSize="sm">
            {children}
          </Text>
        </Flex>
        <div className={css.inputBlocker}>
          {inputType === "checkbox" && (
            <RedoCheckbox label="" setValue={() => {}} value={checked} />
          )}
          {inputType === "radio" && (
            <RadioButton selected={checked} value="" valueChange={() => {}} />
          )}
        </div>
      </Flex>
    </Card>
  );
});
